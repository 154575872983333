@import url("https://cdn.icomoon.io/231278/FHIcons/style.css?l0w67t");
@import "~quill/dist/quill.snow.css";
@import "~quill-mention/dist/quill.mention.css";
/* For use in src/lib/core/theming/_palette.scss */
/* For use in src/lib/core/theming/_palette.scss */
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: var(--font-family);
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 28px / 34px var(--font-family);
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 400 24px / 29px var(--font-family);
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 22px var(--font-family);
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 18px / 25px var(--font-family);
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(16px * 0.83) / 21px var(--font-family);
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(16px * 0.67) / 21px var(--font-family);
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 600 10px / 12px var(--font-family);
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 16px / 21px var(--font-family);
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 18.5px var(--font-family);
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px var(--font-family);
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px var(--font-family);
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px var(--font-family);
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px var(--font-family);
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 16px / 21px var(--font-family);
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: var(--font-family);
}

.mat-card {
  font-family: var(--font-family);
}

.mat-card-title {
  font-size: 28px;
  font-weight: 400;
}

.mat-card-header .mat-card-title {
  font-size: 24px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 16px;
}

.mat-checkbox {
  font-family: var(--font-family);
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 12px;
}

.mat-chip {
  font-size: 10px;
  font-weight: 600;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: var(--font-family);
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 600;
}

.mat-cell, .mat-footer-cell {
  font-size: 16px;
}

.mat-calendar {
  font-family: var(--font-family);
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 400 24px / 29px var(--font-family);
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 16px / 21px var(--font-family);
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: var(--font-family);
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 16px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: var(--font-family);
  font-size: 12px;
}

.mat-radio-button {
  font-family: var(--font-family);
}

.mat-select {
  font-family: var(--font-family);
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: var(--font-family);
}

.mat-slider-thumb-label-text {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 600;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: var(--font-family);
}

.mat-step-label {
  font-size: 16px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 10px;
}

.mat-step-label-selected {
  font-size: 10px;
  font-weight: 600;
}

.mat-tab-group {
  font-family: var(--font-family);
}

.mat-tab-label, .mat-tab-link {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 400 24px / 29px var(--font-family);
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: var(--font-family);
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: var(--font-family);
}

.mat-list-option {
  font-family: var(--font-family);
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.mat-list-base .mat-subheader {
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 600;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 600;
}

.mat-option {
  font-family: var(--font-family);
  font-size: 16px;
}

.mat-optgroup-label {
  font: 600 10px / 12px var(--font-family);
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: var(--font-family);
  font-size: 16px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: var(--font-family);
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 16px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f58675;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #3f3d56;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #f58675;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #3f3d56;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: black;
  background: #f58675;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #3f3d56;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #f58675;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #3f3d56;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #f58675;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #3f3d56;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: black;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #f58675;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #3f3d56;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #f58675;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #3f3d56;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #f58675;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #3f3d56;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #f58675;
  color: black;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: black;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #3f3d56;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(245, 134, 117, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(245, 134, 117, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(245, 134, 117, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #f58675;
  color: black;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(245, 134, 117, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px black;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(245, 134, 117, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(245, 134, 117, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(63, 61, 86, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(63, 61, 86, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(63, 61, 86, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #3f3d56;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(63, 61, 86, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(63, 61, 86, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(63, 61, 86, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #f58675;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #3f3d56;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #f58675;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #3f3d56;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: #3f3d56;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #f58675;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #3f3d56;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #f58675;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #3f3d56;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #f58675;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #3f3d56;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #f58675;
}
.mat-icon.mat-accent {
  color: #3f3d56;
}
.mat-icon.mat-warn {
  color: #f44336;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #f58675;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #3f3d56;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #f9ddd9;
}

.mat-progress-bar-buffer {
  background-color: #f9ddd9;
}

.mat-progress-bar-fill::after {
  background-color: #f58675;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #cbcbd1;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #cbcbd1;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #3f3d56;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #f58675;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #3f3d56;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f58675;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #f58675;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3f3d56;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #3f3d56;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #f58675;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #3f3d56;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #3f3d56;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(63, 61, 86, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #3f3d56;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #f58675;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(245, 134, 117, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #f58675;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #f58675;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: black;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(245, 134, 117, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #3f3d56;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(63, 61, 86, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: black;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #f58675;
  color: black;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #3f3d56;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(252, 219, 214, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #f58675;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: black;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 197, 204, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #3f3d56;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(252, 219, 214, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #f58675;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: black;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: black;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 197, 204, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #3f3d56;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #f58675;
  color: black;
}
.mat-toolbar.mat-accent {
  background: #3f3d56;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #3f3d56;
}

.transparent .mat-select {
  font-size: 16px;
  color: var(--text-color);
}
.transparent .mat-select .mat-select-arrow {
  color: var(--color-primary);
}
.transparent .mat-select-value {
  color: var(--text-color);
}
.transparent .mat-form-field-underline {
  display: none;
}
.transparent .mat-form-field-infix {
  border-top: none;
}
.transparent .mat-form-field-wrapper {
  padding-bottom: 0;
}
.transparent mat-select-trigger {
  display: flex;
  align-items: center;
}

mat-select.border {
  background: var(--white);
  border: 1px solid var(--slate-2);
  border-radius: var(--border-radius);
  padding: 11px 15px;
  line-height: 28px;
}

.mat-select-panel-wrap .mat-select-reset-bttn {
  padding: 16px;
  font-weight: 500;
  font-size: 12px;
  width: 100%;
  justify-content: left;
  position: sticky;
  bottom: 0;
  background: white;
  color: var(--link-color);
}

.mat-select-panel::-webkit-scrollbar,
.mat-autocomplete-panel::-webkit-scrollbar {
  -webkit-appearance: none;
}
.mat-select-panel::-webkit-scrollbar:vertical,
.mat-autocomplete-panel::-webkit-scrollbar:vertical {
  width: 11px;
}
.mat-select-panel::-webkit-scrollbar-thumb,
.mat-autocomplete-panel::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

.dark.mat-select {
  padding: 14px;
  background: rgba(0, 0, 0, 0.6);
  color: var(--white);
  border: none;
  border-radius: 8px;
}
.dark.mat-select .mat-select-value {
  color: var(--white);
}
.dark.mat-select .mat-select-arrow-wrapper {
  width: 30px;
  height: 30px;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--color-primary);
}
.dark.mat-select .mat-select-arrow-wrapper .mat-select-arrow {
  border: solid var(--white);
  border-width: 0 1.5px 1.5px 0;
  margin: 2px 0 0 0;
  display: inline-block;
  padding: 3px;
  transform: translateY(-3px) rotate(45deg);
}
.dark.mat-option {
  background-color: var(--black);
  font-weight: 500;
  font-size: 16px;
  color: var(--white);
}
.dark.mat-option .mat-option-text {
  color: var(--white);
}
.dark.mat-option.mat-selected:not(.mat-option-multiple) {
  color: var(--white);
  background-color: var(--black);
}
.dark.mat-option:hover:not(.mat-option-disabled), .dark.mat-option:focus:not(.mat-option-disabled) {
  background-color: rgba(0, 0, 0, 0.8);
}

.mat-select-panel-expended {
  max-width: unset;
}

.mat-select.select-no-outline {
  background: transparent;
  border: none;
}
.mat-select.select-no-outline:hover .mat-select-arrow-wrapper {
  visibility: visible !important;
}
.mat-select.select-no-outline .mat-select-placeholder {
  font-size: 14px;
}
.mat-select.select-no-outline.show-arrow .mat-select-arrow-wrapper {
  visibility: visible;
  background: transparent;
}
.mat-select.select-no-outline:not(.show-arrow) .mat-select-arrow-wrapper {
  visibility: hidden;
}
.mat-select.select-no-outline .mat-select-arrow-wrapper {
  background: var(--slate-1);
  height: 32px;
  width: 32px;
  border-radius: 4px;
  justify-content: center;
}
.mat-select.select-no-outline .mat-select-arrow-wrapper .mat-select-arrow {
  border-color: var(--color-plum);
}

.option-md {
  min-height: 60px !important;
  height: fit-content !important;
  padding: 5px 16px !important;
}

.mat-dialog-container {
  background-color: var(--white);
  color: var(--text-color);
  border-radius: var(--border-radius) !important;
  position: relative;
  margin-bottom: env(safe-area-inset-bottom);
}
.mat-dialog-container .mat-dialog-actions {
  margin-bottom: 0;
}
.mat-dialog-container mat-dialog-title {
  font-size: 22px;
  line-height: 28px;
  color: var(--text-color);
  margin: 0;
}
.mat-dialog-container .close-button {
  position: absolute;
  top: 20px;
  right: 20px;
}

.bg-slate .mat-dialog-container {
  background-color: var(--slate-000);
}

.bg-grey .mat-dialog-container {
  background-color: var(--slate-00);
}

.dialog-header-style .dialog-header {
  background-color: var(--plum);
  color: var(--white);
  margin: -24px -24px 24px -24px;
  padding: 24px 16px;
  display: flex;
  justify-content: space-between;
}
.dialog-header-style .dialog-header button {
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  background: var(--coral-1);
}
.dialog-header-style .dialog-header button app-icon {
  font-size: 30px;
}
.dialog-header-style.not-dialog {
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--box-shadow);
  margin-bottom: 30px;
  border: 1px solid var(--slate-3);
  display: flex;
  flex-direction: column;
}
.dialog-header-style.not-dialog .dialog-header {
  margin: 0;
}
.dialog-header-style.not-dialog .desc-c {
  padding: 30px 20px;
}
.dialog-header-style.header-primary .dialog-header {
  background-color: var(--color-primary);
  display: flex;
  flex-direction: column;
  justify-content: normal;
}
.dialog-header-style.header-primary .dialog-header button {
  color: var(--color-primary);
  border: none;
  background: var(--coral-1);
}

.dialog-full-screen .mat-dialog-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
.dialog-full-screen .close {
  color: var(--color-primary);
  border: none;
  background: var(--coral-1);
}
.dialog-full-screen .close app-icon {
  font-size: 30px;
}
@media (max-width: 991px) {
  .dialog-full-screen {
    max-width: 100vw !important;
    height: 100%;
    width: 100%;
  }
  .dialog-full-screen .mat-dialog-container {
    border-radius: 0 !important;
  }
  .dialog-full-screen .mat-dialog-content {
    max-height: 78vh;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.69);
}

@media (max-width: 767px) {
  .mobile-bottom-sheet {
    animation-name: slideInBottom;
    animation-duration: 0.2s;
    width: 100%;
    max-width: 100% !important;
    margin-top: auto;
  }
  .mobile-bottom-sheet .mat-dialog-container {
    transition: none;
    border-radius: 30px 30px 0px 0px !important;
    max-height: 95vh;
  }
}
@media (max-width: 767px) {
  .mobile-bottom-sheet-2 {
    animation-name: slideInBottom;
    animation-duration: 0.2s;
    width: 100%;
    max-width: 100% !important;
    margin-top: auto;
  }
  .mobile-bottom-sheet-2 .mat-dialog-container {
    transition: none;
    border-radius: 30px 30px 0px 0px !important;
    height: 80vh;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }
  .mobile-bottom-sheet-2 .mat-dialog-container .mat-dialog-content {
    max-height: none;
    display: flex;
    flex-direction: column;
    min-height: 0;
    min-width: 0;
    flex: 1 1 auto;
    padding-bottom: 24px;
  }
  .mobile-bottom-sheet-2 .mat-dialog-container .mat-dialog-actions {
    display: flex;
    flex-direction: column-reverse;
    padding: 12px 24px 24px;
    margin: 0 -24px;
    box-shadow: 0px -4px 10px 0px rgba(178, 177, 187, 0.15);
    margin-top: 0;
  }
  .mobile-bottom-sheet-2 .mat-dialog-container .mat-dialog-actions button {
    width: 100%;
  }
}
.dialog-dark-backdrop {
  background: rgba(var(--secondary-rgb), 0.95);
}

.dialog-full-footer {
  animation-name: slideInRight;
  animation-duration: 0.3s;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
}
.dialog-full-footer .mat-dialog-container {
  background-color: var(--slate-0);
  width: 100%;
  height: 100%;
  padding-bottom: 0;
  border-radius: 0 !important;
}
.dialog-full-footer .mat-dialog-container .mat-dialog-content {
  flex: 1;
  max-height: none;
  display: flex;
  flex-direction: column;
}
.dialog-full-footer .mat-dialog-container .mat-dialog-content .db-card {
  margin: 0;
  padding: 16px;
  width: 100%;
}
.dialog-full-footer .mat-dialog-container .mat-dialog-content .db-card.flex-1 {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.dialog-full-footer .mat-dialog-container mat-dialog-actions {
  background-color: var(--color-secondary);
  margin: 0 -24px;
  padding: 8px 24px;
}
.dialog-full-footer.show-header {
  position: absolute !important;
  bottom: 0;
  height: calc(100% - 60px);
}

.source-gallery-dialog {
  max-width: 90vw !important;
  width: 100%;
}
.source-gallery-dialog .mat-dialog-content {
  max-height: 80vh !important;
  height: 80vh !important;
}
@media (max-width: 992px) {
  .source-gallery-dialog .mat-dialog-content {
    height: 70vh !important;
  }
}

.get-started-dialog {
  width: 100%;
}

.dialog-full {
  width: 100%;
  height: 100vh;
  max-width: 100vw !important;
  background-color: var(--slate-000);
}
.dialog-full .mat-dialog-container {
  margin: 24px;
  height: auto;
  padding: 0;
  box-shadow: none;
  background: transparent;
  border: 1px solid var(--slate-3);
}
.dialog-full .mat-dialog-container mat-dialog-content {
  background-color: var(--white);
  padding: 24px 24px 0;
  margin: 0;
  border: none;
  max-height: none;
  border-radius: var(--border-radius);
}
.dialog-full .mat-dialog-container .mat-dialog-actions {
  padding: 24px;
  border-top: 1px solid var(--slate-3);
}
.dialog-full.container-dialog .mat-dialog-container {
  margin: 0;
  border: 0;
  background: var(--slate-00);
}

@media (max-width: 991px) {
  .dialog-onboarding {
    max-width: 100vw !important;
    height: 100%;
  }
  .dialog-onboarding .mat-dialog-container {
    display: flex;
    flex-direction: column;
  }
  .dialog-onboarding .mat-dialog-content {
    max-height: none;
  }
}

.all-activites-dialog,
.manage-billing-dialog {
  height: 100vh;
  width: 650px;
}
@media (max-width: 767px) {
  .all-activites-dialog,
.manage-billing-dialog {
    width: 100%;
    max-width: 100% !important;
  }
}
.all-activites-dialog .mat-dialog-container,
.manage-billing-dialog .mat-dialog-container {
  padding: 16px !important;
  transform: none !important;
  border-radius: 0 !important;
}
.all-activites-dialog .mat-dialog-content,
.manage-billing-dialog .mat-dialog-content {
  max-height: none !important;
  margin: 0;
  padding: 0;
}
.all-activites-dialog.no-padding .mat-dialog-container,
.manage-billing-dialog.no-padding .mat-dialog-container {
  padding: 0 !important;
}
.all-activites-dialog.mw-400,
.manage-billing-dialog.mw-400 {
  width: 400px;
}
.all-activites-dialog.mw-450,
.manage-billing-dialog.mw-450 {
  width: 450px;
}
.all-activites-dialog.mw-460,
.manage-billing-dialog.mw-460 {
  width: 460px;
}

.dialog-transparent-header {
  padding: 0;
}
.dialog-transparent-header .mat-dialog-container {
  background-color: transparent;
  box-shadow: none;
}
.dialog-transparent-header .mat-dialog-content {
  background-color: var(--white);
  border-radius: var(--border-radius);
  padding: 0;
  margin: 0;
}

.notify-dialog {
  width: 100%;
}
@media (min-width: 992px) {
  .notify-dialog {
    max-width: 1050px !important;
  }
}

.dialog-full-from-bottom {
  animation-name: slideInBottom;
  animation-duration: 0.4s;
  margin-top: auto;
  max-width: 100vw !important;
}
.dialog-full-from-bottom .mat-dialog-container {
  max-width: 1400px;
  margin: auto;
  height: 95vh;
  border-radius: 30px 30px 0px 0px !important;
}
.dialog-full-from-bottom.h-98 .mat-dialog-container {
  height: 98vh;
}
.dialog-full-from-bottom .header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
}
@media (min-width: 992px) {
  .dialog-full-from-bottom .header {
    padding: 16px;
    border-bottom: 1px solid var(--slate-2);
    margin-bottom: 0;
  }
  .dialog-full-from-bottom .header > * {
    width: 25%;
  }
  .dialog-full-from-bottom .header .nav {
    width: 50%;
  }
}
@media (max-width: 991px) {
  .dialog-full-from-bottom .header {
    flex-wrap: wrap;
  }
  .dialog-full-from-bottom .header .nav {
    order: 3;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .dialog-full-from-bottom {
    width: 100%;
    max-width: 1400px !important;
    height: calc(100vh - 18px);
  }
  .dialog-full-from-bottom .mat-dialog-container {
    padding: 0;
    margin: auto 0 0;
    border-radius: 8px 8px 0px 0px !important;
  }
}
@media (max-width: 767px) {
  .dialog-full-from-bottom {
    min-width: 100vw !important;
  }
}

.padding-bottom-0 .mat-dialog-container {
  padding-bottom: 0;
}
.padding-bottom-0 .mat-dialog-content {
  padding-bottom: 24px;
}

.no-padding .mat-dialog-container {
  padding: 0;
}

.right-panel-bottom-sheet {
  position: absolute !important;
  right: 0;
  animation-name: slideInRight;
  animation-duration: 0.2s;
  height: 100vh;
}
.right-panel-bottom-sheet--small {
  width: 450px;
}
.right-panel-bottom-sheet--medium {
  width: 720px;
}
.right-panel-bottom-sheet .mat-dialog-container {
  padding: 0px !important;
  transform: none !important;
  border-radius: 0 !important;
}
.right-panel-bottom-sheet .mat-dialog-content {
  max-height: none !important;
  margin: 0;
  padding: 0;
}
@media (max-width: 600px) {
  .right-panel-bottom-sheet {
    animation-name: slideInBottom;
    width: 100%;
    max-width: 100% !important;
    height: 95dvh;
    bottom: 0;
  }
  .right-panel-bottom-sheet .mat-dialog-container {
    border-radius: 30px 30px 0 0 !important;
  }
}

.dialog-right-panel {
  height: 100vh;
  width: 420px;
  animation-name: slideInRight;
  animation-duration: 0.2s;
}
@media (max-width: 767px) {
  .dialog-right-panel {
    width: 100%;
    max-width: 100% !important;
  }
}
.dialog-right-panel .mat-dialog-container {
  padding: 24px !important;
  transform: none !important;
  border-radius: 0 !important;
}
.dialog-right-panel .mat-dialog-content {
  max-height: none !important;
  margin: 0;
  padding: 0;
}
.dialog-right-panel button.full-width {
  width: 100%;
}
.dialog-right-panel.no-padding .mat-dialog-container {
  padding: 0 !important;
}
.dialog-right-panel.mw-400 {
  width: 400px;
}
.dialog-right-panel.mw-450 {
  width: 450px;
}
.dialog-right-panel.mw-460 {
  width: 460px;
}
.dialog-right-panel.mw-1024 {
  width: 1024px;
}

.dialog-overflow-visible .mat-dialog-container {
  overflow: visible;
}
.dialog-overflow-visible .mat-dialog-content {
  overflow: visible !important;
}

.dialog-right-in {
  animation-name: slideInRight;
}

.dialog-left-in {
  animation-name: slideInLeft;
}

.dialog-right-in,
.dialog-left-in {
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  max-width: 1400px !important;
  height: 100vh;
  width: 100%;
}
.dialog-right-in .mat-dialog-container,
.dialog-left-in .mat-dialog-container {
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  height: 100vh;
  max-width: 1400px;
  padding: 0;
  margin-left: auto;
  border-radius: 0 !important;
}
.dialog-right-in .mat-dialog-content,
.dialog-left-in .mat-dialog-content {
  max-height: none !important;
  margin: 0;
}
.dialog-right-in .header,
.dialog-left-in .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid var(--slate-2);
  margin-bottom: 0;
}
.dialog-right-in .header.px-24,
.dialog-left-in .header.px-24 {
  padding-left: 24px;
  padding-right: 24px;
}
.dialog-right-in.full-width,
.dialog-left-in.full-width {
  max-width: 100% !important;
}
.dialog-right-in.full-width .mat-dialog-container,
.dialog-left-in.full-width .mat-dialog-container {
  max-width: 100%;
}
@media (max-width: 767px) {
  .dialog-right-in,
.dialog-left-in {
    animation-name: slideInBottom;
    animation-duration: 0.3s;
    width: 100%;
    max-width: 100% !important;
    margin-top: auto;
  }
  .dialog-right-in .mat-dialog-container,
.dialog-left-in .mat-dialog-container {
    margin-top: auto;
    transition: none;
    max-height: 95vh;
  }
  .dialog-right-in .mat-dialog-actions,
.dialog-left-in .mat-dialog-actions {
    min-height: auto;
  }
}

.mat-snack-bar-container {
  border-radius: var(--border-radius) !important;
  min-width: auto !important;
}

simple-snack-bar button.mat-button {
  color: var(--primary);
}

.mat-slider-track-fill,
.mat-slider-wrapper,
.mat-slider-track-wrapper,
.mat-slider-track-background {
  height: 6px !important;
  border-radius: 1000px;
}

.mat-slider.mat-slider {
  padding: 0;
}
.mat-slider.mat-slider.primary .mat-slider-track-fill {
  background-color: var(--color-primary) !important;
}
.mat-slider.mat-slider.secondary .mat-slider-track-fill {
  background-color: var(--color-secondary) !important;
}
.mat-slider.mat-slider.secondary .mat-slider-track-background {
  background-color: var(--slate-1) !important;
}

.mat-slider-thumb.mat-slider-thumb {
  bottom: -12px;
  width: 24px;
  height: 24px;
  border: 3px solid var(--color-primary) !important;
  background-color: var(--white) !important;
  border-radius: 50%;
  transform: scale(1);
}

.mat-progress-bar {
  border-radius: var(--border-radius);
}
.mat-progress-bar.mid {
  height: 7px;
}
.mat-progress-bar.green .mat-progress-bar-buffer {
  background-color: var(--slate-1);
}
.mat-progress-bar.green .mat-progress-bar-fill::after {
  background-color: var(--green);
}

.mat-option.variable-height {
  height: auto;
  padding: 12px;
  border-bottom: 1px solid var(--slate-2);
}

.mat-progress-spinner.theme-white circle,
.mat-progress-spinner.theme-white .mat-spinner circle {
  stroke: var(--white);
}

/* cyrillic-ext */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
:root {
  --primary: #f58675;
  --primary-rgb: 245, 134, 117;
  --secondary: #3f3d56;
  --secondary-rgb: 63, 61, 86;
  --salmon: #f58675;
  --salmon-dark: #e15e4a;
  --salmon-rgb: 245, 134, 117;
  --plum: #3f3d56;
  --plum-000: #fafafa;
  --plum-00: #ececee;
  --plum-1: #d9d8dd;
  --plum-2: #b2b1bb;
  --plum-3: #8c8b9a;
  --plum-4: #656478;
  --plum-5: #3f3d56;
  --plum-7: #262534;
  --plum-dark: #323145;
  --plum-light: #46445e;
  --plum-dark-rgb: 52, 50, 71;
  --white: #ffffff;
  --yellow: #fee8ad;
  --yellow-1: #fef4d6;
  --yellow-4: #fdd15b;
  --yellow-5: #fcc632;
  --yellow-6: #ca9e28;
  --yellow-7: #97771e;
  --yellow-00: #fffaea;
  --yellow-3: #fddd84;
  --dark-grey: #717782;
  --dark-grey-rgb: 113, 119, 130;
  --light-grey: #f2f4f7;
  --light-grey-rgb: 242, 244, 247;
  --border-light-grey: #e4e8ef;
  --border-light-grey-rgb: 228, 232, 239;
  --blue: #0869c9;
  --blue-00: #e7f3ff;
  --blue-000: #f4faff;
  --blue-1: #cee1f4;
  --blue-2: #9cc3e9;
  --blue-4: #3987d4;
  --blue-6: #0654a1;
  --blue-7: #053f79;
  --black: #000000;
  --red: #ff431b;
  --red-00: #ffece8;
  --red-2: #ffb4a4;
  --red-4: #ff6949;
  --red-6: #cc3616;
  --red-1: #ffd9d1;
  --red-7: #992810;
  --red-light: #fbdcd5;
  --red-light-background: rgba(251, 220, 213, 0.5);
  --red-dark: #8c2f1b;
  --orange: #f38e27;
  --orange-00: #fff5eb;
  --orange-1: #fde8d4;
  --orange-4: #f5a552;
  --orange-6: #c2721f;
  --orange-7: #925517;
  --green-7: #336523;
  --green-00: #eef6eb;
  --green-000: #f9fef7;
  --green-2: #bbdcb0;
  --green-4: #77b961;
  --green-6: #44862e;
  --green-light: #ddeed8;
  --green: #55a83a;
  --green-dark: #44862e;
  --slate-0: #f5f5f7;
  --slate-00: #f5f5f7;
  --slate-000: #fafafb;
  --slate-1: #eaecef;
  --slate-2: #d5d8de;
  --slate-3: #c9d1e0;
  --slate-4: #abb1bd;
  --slate-5: #969ead;
  --slate-6: #787e8a;
  --slate-7: #5a5f68;
  --slate-8: #3c3f45;
  --coral-0: #fdf1ef;
  --coral-00: #fdf1ef;
  --coral-000: #fff9f8;
  --coral-1: #fde7e3;
  --coral-5: #f58675;
  --coral-6: #c46b5e;
  --coral-7: #935046;
  --purple: #7f34e0;
  --purple-1: #e5d6f9;
  --inter: inter;
  --font-size-14: 14px;
  --line-height-1-5: 1.5;
  --font-weight-500: 500;
  --border-radius-4: 4px;
  --border-radius-8: 8px;
  --border-radius-10: 10px;
  --border-radius-100: 100px;
  --box-shadow-1: 0 1.5px 3px rgba(86, 105, 125, 0.15);
}

body {
  --color-primary: var(--salmon);
  --color-primary-dark: var(--salmon-dark);
  --color-primary-light: rgba(var(--salmon-rgb), 0.8);
  --color-secondary: var(--plum);
  --color-secondary-dark: var(--plum-dark);
  --font-family: var(--inter);
  --font-weight: var(--font-weight-500);
  --font-size: var(--font-size-14);
  --line-height: var(--line-height-1-5);
  --header-background-color: var(--white);
  --border-radius: var(--border-radius-8);
  --chat-border-radius: var(--border-radius-100);
  --placeholder-color: rgba(var(--dark-grey-rgb), 0.7);
  --box-shadow: var(--box-shadow-1);
  --text-color: var(--plum);
  --text-color-light: var(--plum-3);
  --text-color-light-2: var(--plum-4);
  --background-color: var(--white);
  --body-background-color: var(--slate-00);
  --background-light-color: rgba(var(--light-grey-rgb), 0.5);
  --background-dark-color: var(--border-light-grey);
  --border-color: var(--border-light-grey-rgb);
  --border-dark-color: var(--slate-3);
  --input-background-color: var(--light-grey);
  --link-color: var(--blue);
  --text-color-coral-7: var(--coral-7);
}

[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "fh-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline;
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fh-icon {
  font-family: "fh-icon";
}

[class^=fello-icon-],
[class*=" fello-icon-"] {
  font-family: "felloicons" !important;
}

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: var(--font-weight);
  color: var(--text-color);
  background-color: var(--background-light-color);
}

*,
html,
body {
  font-family: var(--font-family);
}

ul,
ol,
li {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

img {
  max-width: 100%;
}

.h-5 {
  height: 5% !important;
}

.h-10 {
  height: 10% !important;
}

.h-15 {
  height: 15% !important;
}

.h-20 {
  height: 20% !important;
}

.h-25 {
  height: 25% !important;
}

.h-30 {
  height: 30% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

.h-45 {
  height: 45% !important;
}

.h-50 {
  height: 50% !important;
}

.h-55 {
  height: 55% !important;
}

.h-60 {
  height: 60% !important;
}

.h-65 {
  height: 65% !important;
}

.h-70 {
  height: 70% !important;
}

.h-75 {
  height: 75% !important;
}

.h-80 {
  height: 80% !important;
}

.h-85 {
  height: 85% !important;
}

.h-90 {
  height: 90% !important;
}

.h-95 {
  height: 95% !important;
}

.h-100 {
  height: 100% !important;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

.mt-0:not([felloTheme] *) {
  margin-top: 0px !important;
}

.mt-0-ni:not([felloTheme] *) {
  margin-top: 0px;
}

.pt-0:not([felloTheme] *) {
  padding-top: 0px !important;
}

.pt-0-ni:not([felloTheme] *) {
  padding-top: 0px !important;
}

.mb-0:not([felloTheme] *) {
  margin-bottom: 0px !important;
}

.mb-0-ni:not([felloTheme] *) {
  margin-bottom: 0px;
}

.pb-0:not([felloTheme] *) {
  padding-bottom: 0px !important;
}

.pb-0-ni:not([felloTheme] *) {
  padding-bottom: 0px !important;
}

.ml-0:not([felloTheme] *) {
  margin-left: 0px !important;
}

.ml-0-ni:not([felloTheme] *) {
  margin-left: 0px;
}

.pl-0:not([felloTheme] *) {
  padding-left: 0px !important;
}

.pl-0-ni:not([felloTheme] *) {
  padding-left: 0px !important;
}

.mr-0:not([felloTheme] *) {
  margin-right: 0px !important;
}

.mr-0-ni:not([felloTheme] *) {
  margin-right: 0px;
}

.pr-0:not([felloTheme] *) {
  padding-right: 0px !important;
}

.pr-0-ni:not([felloTheme] *) {
  padding-right: 0px !important;
}

.mx-0:not([felloTheme] *) {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.my-0:not([felloTheme] *) {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.p-0:not([felloTheme] *) {
  padding: 0px !important;
}

.p-0-ni:not([felloTheme] *) {
  padding: 0px !important;
}

.mt-1:not([felloTheme] *) {
  margin-top: 4px !important;
}

.mt-1-ni:not([felloTheme] *) {
  margin-top: 4px;
}

.pt-1:not([felloTheme] *) {
  padding-top: 4px !important;
}

.pt-1-ni:not([felloTheme] *) {
  padding-top: 4px !important;
}

.mb-1:not([felloTheme] *) {
  margin-bottom: 4px !important;
}

.mb-1-ni:not([felloTheme] *) {
  margin-bottom: 4px;
}

.pb-1:not([felloTheme] *) {
  padding-bottom: 4px !important;
}

.pb-1-ni:not([felloTheme] *) {
  padding-bottom: 4px !important;
}

.ml-1:not([felloTheme] *) {
  margin-left: 4px !important;
}

.ml-1-ni:not([felloTheme] *) {
  margin-left: 4px;
}

.pl-1:not([felloTheme] *) {
  padding-left: 4px !important;
}

.pl-1-ni:not([felloTheme] *) {
  padding-left: 4px !important;
}

.mr-1:not([felloTheme] *) {
  margin-right: 4px !important;
}

.mr-1-ni:not([felloTheme] *) {
  margin-right: 4px;
}

.pr-1:not([felloTheme] *) {
  padding-right: 4px !important;
}

.pr-1-ni:not([felloTheme] *) {
  padding-right: 4px !important;
}

.mx-1:not([felloTheme] *) {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.my-1:not([felloTheme] *) {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.p-1:not([felloTheme] *) {
  padding: 4px !important;
}

.p-1-ni:not([felloTheme] *) {
  padding: 4px !important;
}

.mt-2:not([felloTheme] *) {
  margin-top: 8px !important;
}

.mt-2-ni:not([felloTheme] *) {
  margin-top: 8px;
}

.pt-2:not([felloTheme] *) {
  padding-top: 8px !important;
}

.pt-2-ni:not([felloTheme] *) {
  padding-top: 8px !important;
}

.mb-2:not([felloTheme] *) {
  margin-bottom: 8px !important;
}

.mb-2-ni:not([felloTheme] *) {
  margin-bottom: 8px;
}

.pb-2:not([felloTheme] *) {
  padding-bottom: 8px !important;
}

.pb-2-ni:not([felloTheme] *) {
  padding-bottom: 8px !important;
}

.ml-2:not([felloTheme] *) {
  margin-left: 8px !important;
}

.ml-2-ni:not([felloTheme] *) {
  margin-left: 8px;
}

.pl-2:not([felloTheme] *) {
  padding-left: 8px !important;
}

.pl-2-ni:not([felloTheme] *) {
  padding-left: 8px !important;
}

.mr-2:not([felloTheme] *) {
  margin-right: 8px !important;
}

.mr-2-ni:not([felloTheme] *) {
  margin-right: 8px;
}

.pr-2:not([felloTheme] *) {
  padding-right: 8px !important;
}

.pr-2-ni:not([felloTheme] *) {
  padding-right: 8px !important;
}

.mx-2:not([felloTheme] *) {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.my-2:not([felloTheme] *) {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.p-2:not([felloTheme] *) {
  padding: 8px !important;
}

.p-2-ni:not([felloTheme] *) {
  padding: 8px !important;
}

.mt-3:not([felloTheme] *) {
  margin-top: 16px !important;
}

.mt-3-ni:not([felloTheme] *) {
  margin-top: 16px;
}

.pt-3:not([felloTheme] *) {
  padding-top: 16px !important;
}

.pt-3-ni:not([felloTheme] *) {
  padding-top: 16px !important;
}

.mb-3:not([felloTheme] *) {
  margin-bottom: 16px !important;
}

.mb-3-ni:not([felloTheme] *) {
  margin-bottom: 16px;
}

.pb-3:not([felloTheme] *) {
  padding-bottom: 16px !important;
}

.pb-3-ni:not([felloTheme] *) {
  padding-bottom: 16px !important;
}

.ml-3:not([felloTheme] *) {
  margin-left: 16px !important;
}

.ml-3-ni:not([felloTheme] *) {
  margin-left: 16px;
}

.pl-3:not([felloTheme] *) {
  padding-left: 16px !important;
}

.pl-3-ni:not([felloTheme] *) {
  padding-left: 16px !important;
}

.mr-3:not([felloTheme] *) {
  margin-right: 16px !important;
}

.mr-3-ni:not([felloTheme] *) {
  margin-right: 16px;
}

.pr-3:not([felloTheme] *) {
  padding-right: 16px !important;
}

.pr-3-ni:not([felloTheme] *) {
  padding-right: 16px !important;
}

.mx-3:not([felloTheme] *) {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.my-3:not([felloTheme] *) {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.p-3:not([felloTheme] *) {
  padding: 16px !important;
}

.p-3-ni:not([felloTheme] *) {
  padding: 16px !important;
}

.mt-4:not([felloTheme] *) {
  margin-top: 28px !important;
}

.mt-4-ni:not([felloTheme] *) {
  margin-top: 28px;
}

.pt-4:not([felloTheme] *) {
  padding-top: 28px !important;
}

.pt-4-ni:not([felloTheme] *) {
  padding-top: 28px !important;
}

.mb-4:not([felloTheme] *) {
  margin-bottom: 28px !important;
}

.mb-4-ni:not([felloTheme] *) {
  margin-bottom: 28px;
}

.pb-4:not([felloTheme] *) {
  padding-bottom: 28px !important;
}

.pb-4-ni:not([felloTheme] *) {
  padding-bottom: 28px !important;
}

.ml-4:not([felloTheme] *) {
  margin-left: 28px !important;
}

.ml-4-ni:not([felloTheme] *) {
  margin-left: 28px;
}

.pl-4:not([felloTheme] *) {
  padding-left: 28px !important;
}

.pl-4-ni:not([felloTheme] *) {
  padding-left: 28px !important;
}

.mr-4:not([felloTheme] *) {
  margin-right: 28px !important;
}

.mr-4-ni:not([felloTheme] *) {
  margin-right: 28px;
}

.pr-4:not([felloTheme] *) {
  padding-right: 28px !important;
}

.pr-4-ni:not([felloTheme] *) {
  padding-right: 28px !important;
}

.mx-4:not([felloTheme] *) {
  margin-left: 28px !important;
  margin-right: 28px !important;
}

.my-4:not([felloTheme] *) {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.p-4:not([felloTheme] *) {
  padding: 28px !important;
}

.p-4-ni:not([felloTheme] *) {
  padding: 28px !important;
}

.mt-5:not([felloTheme] *) {
  margin-top: 48px !important;
}

.mt-5-ni:not([felloTheme] *) {
  margin-top: 48px;
}

.pt-5:not([felloTheme] *) {
  padding-top: 48px !important;
}

.pt-5-ni:not([felloTheme] *) {
  padding-top: 48px !important;
}

.mb-5:not([felloTheme] *) {
  margin-bottom: 48px !important;
}

.mb-5-ni:not([felloTheme] *) {
  margin-bottom: 48px;
}

.pb-5:not([felloTheme] *) {
  padding-bottom: 48px !important;
}

.pb-5-ni:not([felloTheme] *) {
  padding-bottom: 48px !important;
}

.ml-5:not([felloTheme] *) {
  margin-left: 48px !important;
}

.ml-5-ni:not([felloTheme] *) {
  margin-left: 48px;
}

.pl-5:not([felloTheme] *) {
  padding-left: 48px !important;
}

.pl-5-ni:not([felloTheme] *) {
  padding-left: 48px !important;
}

.mr-5:not([felloTheme] *) {
  margin-right: 48px !important;
}

.mr-5-ni:not([felloTheme] *) {
  margin-right: 48px;
}

.pr-5:not([felloTheme] *) {
  padding-right: 48px !important;
}

.pr-5-ni:not([felloTheme] *) {
  padding-right: 48px !important;
}

.mx-5:not([felloTheme] *) {
  margin-left: 48px !important;
  margin-right: 48px !important;
}

.my-5:not([felloTheme] *) {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.p-5:not([felloTheme] *) {
  padding: 48px !important;
}

.p-5-ni:not([felloTheme] *) {
  padding: 48px !important;
}

.m-0:not([felloTheme] *) {
  margin: 0 !important;
}

.m-0-ni {
  margin: 0 !important;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.m-auto:not([felloTheme] *) {
  margin: auto !important;
}

.mt-auto:not([felloTheme] *) {
  margin-top: auto !important;
}

.my-auto:not([felloTheme] *) {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mr-auto:not([felloTheme] *) {
  margin-right: auto !important;
}

.mx-auto:not([felloTheme] *) {
  margin-right: auto !important;
  margin-left: auto !important;
}

.mb-auto:not([felloTheme] *) {
  margin-bottom: auto !important;
}

.ml-auto:not([felloTheme] *) {
  margin-left: auto !important;
}

@media (min-width: 992px) {
  .ml-lg-auto {
    margin-left: auto !important;
  }
}
.p-0:not([felloTheme] *) {
  padding: 0 !important;
}

.p-3:not([felloTheme] *) {
  padding: 16px !important;
}

.py-0:not([felloTheme] *) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1:not([felloTheme] *) {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-2:not([felloTheme] *) {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-3:not([felloTheme] *) {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-4:not([felloTheme] *) {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.py-5:not([felloTheme] *) {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.px-2:not([felloTheme] *) {
  padding-left: 8px;
  padding-right: 8px;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.p-12 {
  padding: 12px !important;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-contents {
  display: contents !important;
}

@media (min-width: 991px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-table {
    display: table !important;
  }
}
@media (max-width: 991px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-block {
    display: block !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .mw-sm-100 {
    max-width: 100% !important;
  }
  .mt-sm-2 {
    margin-top: 8px !important;
  }
  .mb-sm-2 {
    margin-bottom: 8px !important;
  }
  .mb-sm-3 {
    margin-bottom: 16px !important;
  }
  .mb-sm-4 {
    margin-bottom: 28px !important;
  }
}
@media (max-width: 767px) {
  .d-sm-none {
    display: none !important;
  }
}
[hidden] {
  visibility: hidden !important;
}

.flex-box {
  display: flex !important;
}
.flex-box-center {
  display: flex !important;
  align-items: center !important;
}
.flex-box-baseline {
  display: flex !important;
  align-items: baseline !important;
}
.flex-box-align-end {
  display: flex !important;
  align-items: flex-end !important;
}
@media (min-width: 992px) {
  .flex-box-lg-center {
    display: flex !important;
    align-items: center !important;
  }
}
.flex-box-all-center, .introjs-skipbutton.introjs-skipbutton,
.introjs-skipbutton.introjs-skipbutton:hover {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
@media (min-width: 1200px) {
  .flex-box-xl-all-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
.flex-box-justify-center {
  display: flex !important;
  justify-content: center !important;
}
@media (min-width: 992px) {
  .flex-box-lg-justify-center {
    display: flex !important;
    justify-content: center !important;
  }
}
.flex-box-justify-flex-start {
  display: flex !important;
  justify-content: flex-start !important;
}
@media (min-width: 992px) {
  .flex-box-lg-justify-flex-start {
    display: flex !important;
    justify-content: flex-start !important;
  }
}
.flex-box-between, .introjs-tooltipReferenceLayer .introjs-tooltip-header {
  display: flex !important;
  justify-content: space-between !important;
}
@media (min-width: 992px) {
  .flex-box-lg-between {
    display: flex !important;
    justify-content: space-between !important;
  }
}
.flex-box-end {
  display: flex !important;
  justify-content: flex-end !important;
}
@media (min-width: 992px) {
  .flex-box-lg-end {
    display: flex !important;
    justify-content: flex-end !important;
  }
}
.flex-box-center-end {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}
@media (min-width: 992px) {
  .flex-box-lg-center-end {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
  }
}
.flex-box-align-start {
  display: flex !important;
  align-items: flex-start !important;
}
@media (min-width: 992px) {
  .flex-box-lg-align-start {
    display: flex !important;
    align-items: flex-start !important;
  }
}
.flex-box-align-end {
  display: flex !important;
  align-items: flex-end !important;
}
@media (min-width: 992px) {
  .flex-box-lg-align-end {
    display: flex !important;
    align-items: flex-end !important;
  }
}
.flex-box-center-between {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
@media (min-width: 767px) {
  .flex-box-md-center-between {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
}
@media (max-width: 991px) {
  .flex-box-md-center-between {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
}
@media (min-width: 992px) {
  .flex-box-lg-center-between {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
  .flex-box-lg-top-between {
    display: flex !important;
    align-items: baseline !important;
    justify-content: space-between !important;
  }
}
@media (min-width: 1300px) {
  .flex-box-xl-center-between {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
}
.flex-box-baseline-between {
  display: flex !important;
  align-items: baseline !important;
  justify-content: space-between !important;
}
@media (min-width: 992px) {
  .flex-box-lg-baseline-between {
    display: flex !important;
    align-items: baseline !important;
    justify-content: space-between !important;
  }
}
.flex-box-around {
  display: flex !important;
  justify-content: space-around !important;
}
.flex-box-center-around {
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
}
.flex-box-top-between {
  display: flex !important;
  align-items: baseline !important;
  justify-content: space-between !important;
}
.flex-box-end {
  display: flex !important;
  justify-content: flex-end !important;
}
.flex-box-direction-column {
  display: flex !important;
  flex-direction: column !important;
}
.flex-box-direction-column-reverse {
  display: flex !important;
  flex-direction: column-reverse !important;
}
.flex-box-direction-row {
  display: flex !important;
  flex-direction: row !important;
}
@media (min-width: 992px) {
  .flex-box-lg-direction-row {
    display: flex !important;
    flex-direction: row !important;
  }
}
.flex-box-direction-row-reverse {
  display: flex !important;
  flex-direction: row-reverse !important;
}
@media (min-width: 992px) {
  .flex-box-lg-direction-column {
    display: flex !important;
    flex-direction: column !important;
  }
}
.flex-box-start-between {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.flex-box-gap-1 {
  gap: 0.5em;
}
.flex-box-gap-2 {
  gap: 1em;
}
.flex-box-gap-3 {
  gap: 1.5em;
}
.flex-box-gap-4 {
  gap: 2em;
}
.flex-box-gap-5 {
  gap: 2.5em;
}

@media (min-width: 992px) {
  .flex-lg-box {
    display: flex !important;
  }
  .flex-box-lg-all-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}
.flex-grow {
  flex-grow: 1;
}

.flex-direction-row {
  flex-direction: row !important;
}
.flex-direction-column {
  flex-direction: column !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

@media (min-width: 767px) {
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}

@media (min-width: 992px) {
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media (min-width: 1300px) {
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media (max-width: 767px) {
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
}
.flex-1 {
  flex: 1 !important;
}

.flex-item-fixed-sizing {
  min-height: 0;
  min-width: 0;
  flex: 0 0 auto;
}

.flex-item-auto-sizing {
  min-height: 0;
  min-width: 0;
  flex: 1 1 auto;
}

@media (min-width: 767px) {
  .flex-lg-item-auto-sizing {
    min-height: 0;
    min-width: 0;
    flex: 1 1 auto;
  }
}
.flex-item-fixed-max-content {
  flex: 0 0 max-content;
}

@media (min-width: 767px) {
  .flex-lg-box-center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 767px) {
  .flex-lg-box-top-between {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}
.object-fit-contain {
  object-fit: contain !important;
}
.object-fit-cover {
  object-fit: cover !important;
}

.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
@media (min-width: 992px) {
  .position-lg-absolute {
    position: absolute !important;
  }
}

.abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-auto {
  overflow: auto !important;
}
@media (min-width: 992px) {
  .overflow-lg-auto {
    overflow: auto !important;
  }
}
.overflow-y-auto {
  overflow-y: auto !important;
}
.overflow-x-auto {
  overflow-x: auto !important;
}

.mandatory-x-scroll-snapping {
  scroll-snap-type: x mandatory;
}

.scroll-snap-align-start {
  scroll-snap-align: start;
}

.w-auto {
  width: auto !important;
}

.mw-160 {
  min-width: 160px;
  max-width: 160px;
}

.mw-40 {
  max-width: 40px;
}

.w-150 {
  width: 150px;
}

.w-200 {
  width: 200px !important;
}

.w-250 {
  width: 250px;
}

.w-300 {
  width: 300px;
}

.w-350 {
  width: 350px;
}

.w-350-imp {
  width: 350px !important;
}

.w-450 {
  width: 450px;
}

.w-580 {
  width: 580px;
}

.w-fit-content {
  width: fit-content !important;
}

.h-auto {
  height: auto !important;
}

.h-fit-content {
  height: fit-content !important;
}

.h-90px {
  height: 90px;
}

.h-250 {
  height: 250px;
}

.h-350px {
  height: 350px;
}

.h-180px {
  height: 180px;
}

.h-10px {
  height: 10px !important;
}

.h-30px {
  height: 30px !important;
}

.h-60px {
  height: 60px !important;
}

.h-15px {
  height: 15px;
}

.h-25px {
  height: 25px;
}

.c-pointer {
  cursor: pointer;
}

.c-default {
  cursor: default;
}

.pointer-events-none {
  pointer-events: none !important;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.filter-blur-3 {
  filter: blur(3px);
}

.filter-blur-4 {
  filter: blur(4px);
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.border-top-1 {
  border-top: 1px solid var(--slate-2) !important;
}

.border-bottom-1 {
  border-bottom: 1px solid var(--slate-2) !important;
}

.border-left-1 {
  border-left: 1px solid var(--slate-2) !important;
}

.border-right-1 {
  border-right: 1px solid var(--slate-2) !important;
}

.border-top-2 {
  border-top: 2px solid var(--slate-2) !important;
}

.border-bottom-2 {
  border-bottom: 2px solid var(--slate-2) !important;
}

.border-left-2 {
  border-left: 2px solid var(--slate-2) !important;
}

.border-right-2 {
  border-right: 2px solid var(--slate-2) !important;
}

.border-none {
  border: none !important;
}

.border-radius-none {
  border-radius: 0 !important;
}

.border-radius-50 {
  border-radius: 50% !important;
}

.progress-circular {
  display: block;
  margin: 0;
  height: 25px;
  width: 25px;
}
.progress-circular .circle {
  stroke: var(--color-primary);
  fill: none;
  stroke-width: 4;
}
.progress-circular .outer-circle {
  stroke: var(--slate-1);
  fill: none;
  stroke-width: 4;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
.top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.dummy-data {
  display: none;
}

.dummy-view .actual-data {
  display: none;
}
.dummy-view .dummy-data {
  display: block;
}

.mw-700 {
  min-width: 775px;
}

.mw-720 {
  min-width: 720px;
}

.mw-16 {
  min-width: 16px;
}

.max-height-none {
  max-height: none !important;
}

.max-height-480 {
  max-height: 480px !important;
}

.max-width-180 {
  max-width: 180px;
}

.max-width-220 {
  max-width: 220px;
}

.max-width-220-imp {
  max-width: 220px !important;
}

.max-width-300 {
  max-width: 300px;
}

.max-width-300-imp {
  max-width: 300px !important;
}

.max-width-390 {
  max-width: 390px;
}

.max-width-390-imp {
  max-width: 390px !important;
}

.max-width-550 {
  max-width: 550px;
}

.container-type-inline {
  container-type: inline-size;
}

.container-type-size {
  container-type: size;
}

.visible-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
}
.visible-scrollbar::-webkit-scrollbar:vertical {
  width: 11px;
}
.visible-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

.badge {
  background-color: var(--red);
  border-radius: 100px;
  padding: 4px;
  color: var(--white);
  min-width: 20px;
  text-align: center;
}

.table-layout-fixed {
  table-layout: fixed !important;
}

.box-shadow {
  box-shadow: var(--box-shadow) !important;
}

.box-shadow-none {
  box-shadow: none !important;
}

.box-shadow-top {
  box-shadow: 0px -4px 10px 0px rgba(178, 177, 187, 0.15);
}

ul.ul-disc {
  list-style: disc;
  padding-left: 15px;
}
ul.ul-disc li {
  list-style: disc;
}

.border-1 {
  border-radius: var(--border-radius);
  padding: 8px;
  border: 1px solid var(--slate-2);
}

.rounded {
  --border-radius: 8px;
}

.semi-rounded {
  --border-radius: 4px;
}

.squared {
  --border-radius: 0;
}

.fully-rounded {
  --border-radius: 40px;
}

.yellow-banner {
  background: var(--yellow-5);
  padding: 8px 24px;
}

.border-color-primary {
  border-color: var(--primary) !important;
}
.border-color-primary-rgb {
  border-color: var(--primary-rgb) !important;
}
.border-color-secondary {
  border-color: var(--secondary) !important;
}
.border-color-secondary-rgb {
  border-color: var(--secondary-rgb) !important;
}
.border-color-salmon {
  border-color: var(--salmon) !important;
}
.border-color-salmon-dark {
  border-color: var(--salmon-dark) !important;
}
.border-color-salmon-rgb {
  border-color: var(--salmon-rgb) !important;
}
.border-color-plum {
  border-color: var(--plum) !important;
}
.border-color-plum-000 {
  border-color: var(--plum-000) !important;
}
.border-color-plum-00 {
  border-color: var(--plum-00) !important;
}
.border-color-plum-1 {
  border-color: var(--plum-1) !important;
}
.border-color-plum-2 {
  border-color: var(--plum-2) !important;
}
.border-color-plum-3 {
  border-color: var(--plum-3) !important;
}
.border-color-plum-4 {
  border-color: var(--plum-4) !important;
}
.border-color-plum-5 {
  border-color: var(--plum-5) !important;
}
.border-color-plum-7 {
  border-color: var(--plum-7) !important;
}
.border-color-plum-dark {
  border-color: var(--plum-dark) !important;
}
.border-color-plum-light {
  border-color: var(--plum-light) !important;
}
.border-color-plum-dark-rgb {
  border-color: var(--plum-dark-rgb) !important;
}
.border-color-white {
  border-color: var(--white) !important;
}
.border-color-yellow {
  border-color: var(--yellow) !important;
}
.border-color-yellow-1 {
  border-color: var(--yellow-1) !important;
}
.border-color-yellow-4 {
  border-color: var(--yellow-4) !important;
}
.border-color-yellow-5 {
  border-color: var(--yellow-5) !important;
}
.border-color-yellow-6 {
  border-color: var(--yellow-6) !important;
}
.border-color-yellow-7 {
  border-color: var(--yellow-7) !important;
}
.border-color-yellow-00 {
  border-color: var(--yellow-00) !important;
}
.border-color-yellow-3 {
  border-color: var(--yellow-3) !important;
}
.border-color-dark-grey {
  border-color: var(--dark-grey) !important;
}
.border-color-dark-grey-rgb {
  border-color: var(--dark-grey-rgb) !important;
}
.border-color-light-grey {
  border-color: var(--light-grey) !important;
}
.border-color-light-grey-rgb {
  border-color: var(--light-grey-rgb) !important;
}
.border-color-border-light-grey {
  border-color: var(--border-light-grey) !important;
}
.border-color-border-light-grey-rgb {
  border-color: var(--border-light-grey-rgb) !important;
}
.border-color-coral-0 {
  border-color: var(--coral-0) !important;
}
.border-color-coral-00 {
  border-color: var(--coral-00) !important;
}
.border-color-coral-000 {
  border-color: var(--coral-000) !important;
}
.border-color-coral-1 {
  border-color: var(--coral-1) !important;
}
.border-color-coral-5 {
  border-color: var(--coral-5) !important;
}
.border-color-coral-6 {
  border-color: var(--coral-6) !important;
}
.border-color-coral-7 {
  border-color: var(--coral-7) !important;
}
.border-color-purple {
  border-color: var(--purple) !important;
}
.border-color-purple-1 {
  border-color: var(--purple-1) !important;
}
.border-color-blue {
  border-color: var(--blue) !important;
}
.border-color-blue-00 {
  border-color: var(--blue-00) !important;
}
.border-color-blue-000 {
  border-color: var(--blue-000) !important;
}
.border-color-blue-1 {
  border-color: var(--blue-1) !important;
}
.border-color-blue-2 {
  border-color: var(--blue-2) !important;
}
.border-color-blue-4 {
  border-color: var(--blue-4) !important;
}
.border-color-blue-6 {
  border-color: var(--blue-6) !important;
}
.border-color-blue-7 {
  border-color: var(--blue-7) !important;
}
.border-color-black {
  border-color: var(--black) !important;
}
.border-color-red {
  border-color: var(--red) !important;
}
.border-color-red-00 {
  border-color: var(--red-00) !important;
}
.border-color-red-2 {
  border-color: var(--red-2) !important;
}
.border-color-red-4 {
  border-color: var(--red-4) !important;
}
.border-color-red-6 {
  border-color: var(--red-6) !important;
}
.border-color-red-1 {
  border-color: var(--red-1) !important;
}
.border-color-red-7 {
  border-color: var(--red-7) !important;
}
.border-color-red-light {
  border-color: var(--red-light) !important;
}
.border-color-red-light-background {
  border-color: var(--red-light-background) !important;
}
.border-color-red-dark {
  border-color: var(--red-dark) !important;
}
.border-color-orange {
  border-color: var(--orange) !important;
}
.border-color-orange-00 {
  border-color: var(--orange-00) !important;
}
.border-color-orange-1 {
  border-color: var(--orange-1) !important;
}
.border-color-orange-4 {
  border-color: var(--orange-4) !important;
}
.border-color-orange-6 {
  border-color: var(--orange-6) !important;
}
.border-color-orange-7 {
  border-color: var(--orange-7) !important;
}
.border-color-green-00 {
  border-color: var(--green-00) !important;
}
.border-color-green-2 {
  border-color: var(--green-2) !important;
}
.border-color-green-4 {
  border-color: var(--green-4) !important;
}
.border-color-green-6 {
  border-color: var(--green-6) !important;
}
.border-color-green-7 {
  border-color: var(--green-7) !important;
}
.border-color-green-000 {
  border-color: var(--green-000) !important;
}
.border-color-green-light {
  border-color: var(--green-light) !important;
}
.border-color-green {
  border-color: var(--green) !important;
}
.border-color-green-dark {
  border-color: var(--green-dark) !important;
}
.border-color-slate-0 {
  border-color: var(--slate-0) !important;
}
.border-color-slate-00 {
  border-color: var(--slate-00) !important;
}
.border-color-slate-000 {
  border-color: var(--slate-000) !important;
}
.border-color-slate-1 {
  border-color: var(--slate-1) !important;
}
.border-color-slate-2 {
  border-color: var(--slate-2) !important;
}
.border-color-slate-3 {
  border-color: var(--slate-3) !important;
}
.border-color-slate-4 {
  border-color: var(--slate-4) !important;
}
.border-color-slate-5 {
  border-color: var(--slate-5) !important;
}
.border-color-slate-8 {
  border-color: var(--slate-8) !important;
}
.border-color-slate-7 {
  border-color: var(--slate-7) !important;
}
.border-color-slate-6 {
  border-color: var(--slate-6) !important;
}

.no-border {
  border: none !important;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.ai-gradient {
  background: linear-gradient(75.05deg, #0dc1c4 9.38%, #3d93f5 29.11%, #8d6ae7 48.84%, #eb61a2 68.57%, #f79e91 88.31%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.ai-gradient-border {
  border: 2px solid;
  border-image: linear-gradient(75.05deg, #0dc1c4 9.38%, #3d93f5 29.11%, #8d6ae7 48.84%, #eb61a2 68.57%, #f79e91 88.31%);
  border-image-slice: 1;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
p {
  margin: 0;
}

a {
  text-decoration: none;
  color: var(--text-color);
}

.text-italic {
  font-style: italic !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-strikethrough {
  text-decoration: line-through;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-sentence-case::first-letter {
  text-transform: uppercase !important;
}

.text-lower-sentence-case::first-letter {
  text-transform: lowercase !important;
}

.text-underline {
  text-decoration: underline !important;
  text-underline-offset: 1px;
}

.hover-underline:hover {
  text-decoration: underline;
}

.hover-underline-container:hover .hover-underline-item {
  text-decoration: underline;
}

.hover-no-underline:hover {
  text-decoration: none !important;
}

.hover-background:not(:hover) {
  background-color: transparent !important;
}

.hover-opacity-1 {
  opacity: 0 !important;
}
.hover-opacity-1:hover {
  opacity: 1 !important;
}

.text-right {
  text-align: right !important;
}

.hover-container .hover-show {
  opacity: 0;
}
.hover-container:hover .hover-show {
  opacity: 1;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
.vertical-middle {
  vertical-align: middle !important;
}

.text-color-primary {
  color: var(--color-primary) !important;
}

.text-color-secondary {
  color: var(--color-secondary) !important;
}

.text-color-light-2 {
  color: var(--text-color-light-2);
}

.text-color-facebook {
  color: #1877f2 !important;
}

.text-color-twitter {
  color: #1da1f2 !important;
}

.text-color-linkedin {
  color: #0077b5 !important;
}

.text-color-whatsapp {
  color: #25d366 !important;
}

.text-color-instagram {
  background: linear-gradient(0deg, #dc8d40 0.47%, #c3256c 57.42%, #7423c0 110.91%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.text-transform-none {
  text-transform: none !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.multiline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.word-break {
  white-space: normal;
  word-break: break-word;
}

.word-wrap-break-word {
  word-wrap: break-word;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-1-2 {
  line-height: 1.2 !important;
}

.line-height-1_5 {
  line-height: 1.5 !important;
}

.line-height-2 {
  line-height: 2 !important;
}

.whitespace-normal {
  white-space: normal !important;
}

.whitespace-nowrap {
  white-space: nowrap !important;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.text-size-8 {
  font-size: 8px !important;
}

.text-size-9 {
  font-size: 9px !important;
}

.text-size-10 {
  font-size: 10px !important;
}

.text-size-11 {
  font-size: 11px !important;
}

.text-size-12 {
  font-size: 12px !important;
}

.text-size-13 {
  font-size: 13px !important;
}

.text-size-14 {
  font-size: 14px !important;
}

.text-size-16 {
  font-size: 16px !important;
}

.text-size-18 {
  font-size: 18px !important;
}

.text-size-20 {
  font-size: 20px !important;
}

.text-size-22 {
  font-size: 22px !important;
}

.text-size-24 {
  font-size: 24px !important;
}

.text-size-25 {
  font-size: 25px !important;
}

.text-size-26 {
  font-size: 26px !important;
}

.text-size-28 {
  font-size: 28px !important;
}

.text-size-30 {
  font-size: 30px !important;
}

.text-size-32 {
  font-size: 32px !important;
}

.text-size-35 {
  font-size: 35px !important;
}

.text-size-36 {
  font-size: 36px !important;
}

.text-size-40 {
  font-size: 40px !important;
}

.text-size-44 {
  font-size: 44px !important;
}

.text-size-45 {
  font-size: 45px !important;
}

.text-size-50 {
  font-size: 50px !important;
}

.text-size-55 {
  font-size: 55px !important;
}

.text-size-60 {
  font-size: 60px !important;
}

.text-color-primary {
  color: var(--primary) !important;
}
.text-color-primary-rgb {
  color: var(--primary-rgb) !important;
}
.text-color-secondary {
  color: var(--secondary) !important;
}
.text-color-secondary-rgb {
  color: var(--secondary-rgb) !important;
}
.text-color-salmon {
  color: var(--salmon) !important;
}
.text-color-salmon-dark {
  color: var(--salmon-dark) !important;
}
.text-color-salmon-rgb {
  color: var(--salmon-rgb) !important;
}
.text-color-plum {
  color: var(--plum) !important;
}
.text-color-plum-000 {
  color: var(--plum-000) !important;
}
.text-color-plum-00 {
  color: var(--plum-00) !important;
}
.text-color-plum-1 {
  color: var(--plum-1) !important;
}
.text-color-plum-2 {
  color: var(--plum-2) !important;
}
.text-color-plum-3 {
  color: var(--plum-3) !important;
}
.text-color-plum-4 {
  color: var(--plum-4) !important;
}
.text-color-plum-5 {
  color: var(--plum-5) !important;
}
.text-color-plum-7 {
  color: var(--plum-7) !important;
}
.text-color-plum-dark {
  color: var(--plum-dark) !important;
}
.text-color-plum-light {
  color: var(--plum-light) !important;
}
.text-color-plum-dark-rgb {
  color: var(--plum-dark-rgb) !important;
}
.text-color-white {
  color: var(--white) !important;
}
.text-color-yellow {
  color: var(--yellow) !important;
}
.text-color-yellow-1 {
  color: var(--yellow-1) !important;
}
.text-color-yellow-4 {
  color: var(--yellow-4) !important;
}
.text-color-yellow-5 {
  color: var(--yellow-5) !important;
}
.text-color-yellow-6 {
  color: var(--yellow-6) !important;
}
.text-color-yellow-7 {
  color: var(--yellow-7) !important;
}
.text-color-yellow-00 {
  color: var(--yellow-00) !important;
}
.text-color-yellow-3 {
  color: var(--yellow-3) !important;
}
.text-color-dark-grey {
  color: var(--dark-grey) !important;
}
.text-color-dark-grey-rgb {
  color: var(--dark-grey-rgb) !important;
}
.text-color-light-grey {
  color: var(--light-grey) !important;
}
.text-color-light-grey-rgb {
  color: var(--light-grey-rgb) !important;
}
.text-color-border-light-grey {
  color: var(--border-light-grey) !important;
}
.text-color-border-light-grey-rgb {
  color: var(--border-light-grey-rgb) !important;
}
.text-color-coral-0 {
  color: var(--coral-0) !important;
}
.text-color-coral-00 {
  color: var(--coral-00) !important;
}
.text-color-coral-000 {
  color: var(--coral-000) !important;
}
.text-color-coral-1 {
  color: var(--coral-1) !important;
}
.text-color-coral-5 {
  color: var(--coral-5) !important;
}
.text-color-coral-6 {
  color: var(--coral-6) !important;
}
.text-color-coral-7 {
  color: var(--coral-7) !important;
}
.text-color-purple {
  color: var(--purple) !important;
}
.text-color-purple-1 {
  color: var(--purple-1) !important;
}
.text-color-blue {
  color: var(--blue) !important;
}
.text-color-blue-00 {
  color: var(--blue-00) !important;
}
.text-color-blue-000 {
  color: var(--blue-000) !important;
}
.text-color-blue-1 {
  color: var(--blue-1) !important;
}
.text-color-blue-2 {
  color: var(--blue-2) !important;
}
.text-color-blue-4 {
  color: var(--blue-4) !important;
}
.text-color-blue-6 {
  color: var(--blue-6) !important;
}
.text-color-blue-7 {
  color: var(--blue-7) !important;
}
.text-color-black {
  color: var(--black) !important;
}
.text-color-red {
  color: var(--red) !important;
}
.text-color-red-00 {
  color: var(--red-00) !important;
}
.text-color-red-2 {
  color: var(--red-2) !important;
}
.text-color-red-4 {
  color: var(--red-4) !important;
}
.text-color-red-6 {
  color: var(--red-6) !important;
}
.text-color-red-1 {
  color: var(--red-1) !important;
}
.text-color-red-7 {
  color: var(--red-7) !important;
}
.text-color-red-light {
  color: var(--red-light) !important;
}
.text-color-red-light-background {
  color: var(--red-light-background) !important;
}
.text-color-red-dark {
  color: var(--red-dark) !important;
}
.text-color-orange {
  color: var(--orange) !important;
}
.text-color-orange-00 {
  color: var(--orange-00) !important;
}
.text-color-orange-1 {
  color: var(--orange-1) !important;
}
.text-color-orange-4 {
  color: var(--orange-4) !important;
}
.text-color-orange-6 {
  color: var(--orange-6) !important;
}
.text-color-orange-7 {
  color: var(--orange-7) !important;
}
.text-color-green-00 {
  color: var(--green-00) !important;
}
.text-color-green-2 {
  color: var(--green-2) !important;
}
.text-color-green-4 {
  color: var(--green-4) !important;
}
.text-color-green-6 {
  color: var(--green-6) !important;
}
.text-color-green-7 {
  color: var(--green-7) !important;
}
.text-color-green-000 {
  color: var(--green-000) !important;
}
.text-color-green-light {
  color: var(--green-light) !important;
}
.text-color-green {
  color: var(--green) !important;
}
.text-color-green-dark {
  color: var(--green-dark) !important;
}
.text-color-slate-0 {
  color: var(--slate-0) !important;
}
.text-color-slate-00 {
  color: var(--slate-00) !important;
}
.text-color-slate-000 {
  color: var(--slate-000) !important;
}
.text-color-slate-1 {
  color: var(--slate-1) !important;
}
.text-color-slate-2 {
  color: var(--slate-2) !important;
}
.text-color-slate-3 {
  color: var(--slate-3) !important;
}
.text-color-slate-4 {
  color: var(--slate-4) !important;
}
.text-color-slate-5 {
  color: var(--slate-5) !important;
}
.text-color-slate-8 {
  color: var(--slate-8) !important;
}
.text-color-slate-7 {
  color: var(--slate-7) !important;
}
.text-color-slate-6 {
  color: var(--slate-6) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}
.bg-primary-rgb {
  background-color: var(--primary-rgb) !important;
}
.bg-secondary {
  background-color: var(--secondary) !important;
}
.bg-secondary-rgb {
  background-color: var(--secondary-rgb) !important;
}
.bg-salmon {
  background-color: var(--salmon) !important;
}
.bg-salmon-dark {
  background-color: var(--salmon-dark) !important;
}
.bg-salmon-rgb {
  background-color: var(--salmon-rgb) !important;
}
.bg-plum {
  background-color: var(--plum) !important;
}
.bg-plum-000 {
  background-color: var(--plum-000) !important;
}
.bg-plum-00 {
  background-color: var(--plum-00) !important;
}
.bg-plum-1 {
  background-color: var(--plum-1) !important;
}
.bg-plum-2 {
  background-color: var(--plum-2) !important;
}
.bg-plum-3 {
  background-color: var(--plum-3) !important;
}
.bg-plum-4 {
  background-color: var(--plum-4) !important;
}
.bg-plum-5 {
  background-color: var(--plum-5) !important;
}
.bg-plum-7 {
  background-color: var(--plum-7) !important;
}
.bg-plum-dark {
  background-color: var(--plum-dark) !important;
}
.bg-plum-light {
  background-color: var(--plum-light) !important;
}
.bg-plum-dark-rgb {
  background-color: var(--plum-dark-rgb) !important;
}
.bg-white {
  background-color: var(--white) !important;
}
.bg-yellow {
  background-color: var(--yellow) !important;
}
.bg-yellow-1 {
  background-color: var(--yellow-1) !important;
}
.bg-yellow-4 {
  background-color: var(--yellow-4) !important;
}
.bg-yellow-5 {
  background-color: var(--yellow-5) !important;
}
.bg-yellow-6 {
  background-color: var(--yellow-6) !important;
}
.bg-yellow-7 {
  background-color: var(--yellow-7) !important;
}
.bg-yellow-00 {
  background-color: var(--yellow-00) !important;
}
.bg-yellow-3 {
  background-color: var(--yellow-3) !important;
}
.bg-dark-grey {
  background-color: var(--dark-grey) !important;
}
.bg-dark-grey-rgb {
  background-color: var(--dark-grey-rgb) !important;
}
.bg-light-grey {
  background-color: var(--light-grey) !important;
}
.bg-light-grey-rgb {
  background-color: var(--light-grey-rgb) !important;
}
.bg-border-light-grey {
  background-color: var(--border-light-grey) !important;
}
.bg-border-light-grey-rgb {
  background-color: var(--border-light-grey-rgb) !important;
}
.bg-coral-0 {
  background-color: var(--coral-0) !important;
}
.bg-coral-00 {
  background-color: var(--coral-00) !important;
}
.bg-coral-000 {
  background-color: var(--coral-000) !important;
}
.bg-coral-1 {
  background-color: var(--coral-1) !important;
}
.bg-coral-5 {
  background-color: var(--coral-5) !important;
}
.bg-coral-6 {
  background-color: var(--coral-6) !important;
}
.bg-coral-7 {
  background-color: var(--coral-7) !important;
}
.bg-purple {
  background-color: var(--purple) !important;
}
.bg-purple-1 {
  background-color: var(--purple-1) !important;
}
.bg-blue {
  background-color: var(--blue) !important;
}
.bg-blue-00 {
  background-color: var(--blue-00) !important;
}
.bg-blue-000 {
  background-color: var(--blue-000) !important;
}
.bg-blue-1 {
  background-color: var(--blue-1) !important;
}
.bg-blue-2 {
  background-color: var(--blue-2) !important;
}
.bg-blue-4 {
  background-color: var(--blue-4) !important;
}
.bg-blue-6 {
  background-color: var(--blue-6) !important;
}
.bg-blue-7 {
  background-color: var(--blue-7) !important;
}
.bg-black {
  background-color: var(--black) !important;
}
.bg-red {
  background-color: var(--red) !important;
}
.bg-red-00 {
  background-color: var(--red-00) !important;
}
.bg-red-2 {
  background-color: var(--red-2) !important;
}
.bg-red-4 {
  background-color: var(--red-4) !important;
}
.bg-red-6 {
  background-color: var(--red-6) !important;
}
.bg-red-1 {
  background-color: var(--red-1) !important;
}
.bg-red-7 {
  background-color: var(--red-7) !important;
}
.bg-red-light {
  background-color: var(--red-light) !important;
}
.bg-red-light-background {
  background-color: var(--red-light-background) !important;
}
.bg-red-dark {
  background-color: var(--red-dark) !important;
}
.bg-orange {
  background-color: var(--orange) !important;
}
.bg-orange-00 {
  background-color: var(--orange-00) !important;
}
.bg-orange-1 {
  background-color: var(--orange-1) !important;
}
.bg-orange-4 {
  background-color: var(--orange-4) !important;
}
.bg-orange-6 {
  background-color: var(--orange-6) !important;
}
.bg-orange-7 {
  background-color: var(--orange-7) !important;
}
.bg-green-00 {
  background-color: var(--green-00) !important;
}
.bg-green-2 {
  background-color: var(--green-2) !important;
}
.bg-green-4 {
  background-color: var(--green-4) !important;
}
.bg-green-6 {
  background-color: var(--green-6) !important;
}
.bg-green-7 {
  background-color: var(--green-7) !important;
}
.bg-green-000 {
  background-color: var(--green-000) !important;
}
.bg-green-light {
  background-color: var(--green-light) !important;
}
.bg-green {
  background-color: var(--green) !important;
}
.bg-green-dark {
  background-color: var(--green-dark) !important;
}
.bg-slate-0 {
  background-color: var(--slate-0) !important;
}
.bg-slate-00 {
  background-color: var(--slate-00) !important;
}
.bg-slate-000 {
  background-color: var(--slate-000) !important;
}
.bg-slate-1 {
  background-color: var(--slate-1) !important;
}
.bg-slate-2 {
  background-color: var(--slate-2) !important;
}
.bg-slate-3 {
  background-color: var(--slate-3) !important;
}
.bg-slate-4 {
  background-color: var(--slate-4) !important;
}
.bg-slate-5 {
  background-color: var(--slate-5) !important;
}
.bg-slate-8 {
  background-color: var(--slate-8) !important;
}
.bg-slate-7 {
  background-color: var(--slate-7) !important;
}
.bg-slate-6 {
  background-color: var(--slate-6) !important;
}

.bg-transparent {
  background: transparent !important;
}

.bg-gradient-ai {
  background: linear-gradient(98deg, #e7f1fe 3.79%, #f2edfc 35.43%, #fff2f3 67.07%, #fde7e3 98.71%);
}

.text-color-light, .introjs-tooltiptext {
  color: var(--plum-4);
}

.fw-100 {
  font-weight: 100 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.opacity-0 {
  opacity: calc(0 / 100) !important;
}

@media (min-width: 992px) {
  .opacity-lg-0 {
    opacity: calc(0 / 100) !important;
  }
}
.opacity-25 {
  opacity: calc(25 / 100) !important;
}

@media (min-width: 992px) {
  .opacity-lg-25 {
    opacity: calc(25 / 100) !important;
  }
}
.opacity-50 {
  opacity: calc(50 / 100) !important;
}

@media (min-width: 992px) {
  .opacity-lg-50 {
    opacity: calc(50 / 100) !important;
  }
}
.opacity-75 {
  opacity: calc(75 / 100) !important;
}

@media (min-width: 992px) {
  .opacity-lg-75 {
    opacity: calc(75 / 100) !important;
  }
}
.opacity-100 {
  opacity: calc(100 / 100) !important;
}

@media (min-width: 992px) {
  .opacity-lg-100 {
    opacity: calc(100 / 100) !important;
  }
}
.filter-grayscale-0 {
  filter: grayscale(0);
}

.filter-grayscale-1 {
  filter: grayscale(1);
}

.font-courier {
  font-family: "Courier";
}

.error-field {
  color: var(--red);
  font-size: 12px;
}

.search-highlight {
  background: var(--yellow-3);
}

.link-underline a {
  text-decoration: underline;
  text-underline-offset: 1px;
}

.custom-theme button:not(.link),
.custom-theme .button:not(.link) {
  border-radius: var(--theme-border-radius);
  color: var(--btn-text-color);
}
.custom-theme button:not(.link).button-primary,
.custom-theme .button:not(.link).button-primary {
  color: var(--plum);
  border-color: var(--color-primary);
  background: white;
  font-weight: 600;
}
.custom-theme .widget-input-c,
.custom-theme input:not([felloTheme] *) {
  border-radius: var(--theme-border-radius);
}

.print-only {
  display: none !important;
}

.to-print .print-d-none,
.to-print ngx-skeleton-loader {
  display: none !important;
}
.to-print .print-only {
  display: block !important;
}
.to-print .print-border-none {
  border: none;
}
.to-print .page-break-inside-avoid {
  break-inside: avoid;
}
.to-print .page-break-before {
  page-break-before: always;
}
.to-print .page-break-after {
  page-break-after: always;
}

@media print {
  #userflow-ui {
    display: none;
  }
}
.form-field {
  position: relative;
  width: 100%;
}
.form-field.prefix input[type=text] {
  padding-left: 35px;
}
.form-field:after {
  content: attr(data-prefix);
  position: absolute;
  top: 15px;
  left: 15px;
  color: var(--color-secondary);
  font-size: 20px;
  line-height: 28px;
}
.form-field.suffix input[type=text] {
  padding-right: 35px;
}
.form-field:before {
  content: attr(data-suffix);
  position: absolute;
  top: 15px;
  right: 15px;
  color: var(--color-secondary);
  font-size: 20px;
  line-height: 28px;
}

input:not([felloTheme] *),
textarea:not([felloTheme] *) {
  font-family: var(--font-family);
  background: var(--input-background-color);
  border-radius: 10px;
  padding: 16px 15px;
  color: var(--color-secondary);
  width: 100%;
  font-size: 20px;
  line-height: 26px;
  border: none;
  outline: none;
  resize: none;
  margin: 0;
}
input:not([felloTheme] *)::placeholder,
textarea:not([felloTheme] *)::placeholder {
  color: var(--placeholder-color);
  font-weight: 400;
}
input:not([felloTheme] *):disabled:not(.fake-disabled),
textarea:not([felloTheme] *):disabled:not(.fake-disabled) {
  opacity: 0.5;
  pointer-events: none;
}
input:not([felloTheme] *).fake-disabled:disabled,
textarea:not([felloTheme] *).fake-disabled:disabled {
  cursor: pointer;
}
input:not([felloTheme] *).disabled-grey:disabled,
textarea:not([felloTheme] *).disabled-grey:disabled {
  background: var(--slate-00);
  opacity: 1;
}

input:-webkit-autofill:not([felloTheme] *),
input:-webkit-autofill:hover:not([felloTheme] *),
input:-webkit-autofill:focus:not([felloTheme] *),
input:-webkit-autofill:active:not([felloTheme] *) {
  -webkit-box-shadow: 0 0 0 30px var(--input-background-color) inset !important;
  -webkit-text-fill-color: var(--color-secondary) !important;
}

@media (min-width: 768px) {
  input[type=text]:not([felloTheme] *),
input[type=password]:not([felloTheme] *) {
    font-size: 18px;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number]:not([felloTheme] *) {
  -moz-appearance: textfield;
}

.number-counter {
  display: flex;
  color: var(--color-secondary);
  width: 165px;
  border: 1px solid var(--slate-2);
  border-radius: 8px;
  overflow: hidden;
}
.number-counter button {
  height: 40px;
  width: 40px;
  background: var(--slate-0);
  border-radius: 0;
  color: var(--blue);
}
.number-counter input:not([felloTheme] *) {
  padding: 0;
  border: none;
  text-align: center;
  width: fit-content;
}
.number-counter .value-c {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  border-left: 1px solid var(--slate-2);
  border-right: 1px solid var(--slate-2);
}

.widget-input-c {
  max-width: 650px;
}
@container (max-width: 600px) {
  .widget-input-c input:not([felloTheme] *) {
    padding: 15px 12px;
  }
  .widget-input-c button {
    margin: 16px 0 0;
    width: 100%;
    position: relative;
  }
}
@container (min-width: 600px) {
  .widget-input-c {
    background: var(--white);
    border: 1px solid var(--slate-2);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    position: relative;
    color: var(--color-secondary);
    width: 100%;
    font-size: 20px;
    line-height: 26px;
    padding: 5px 6px;
  }
  .widget-input-c button {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    padding: 16px 25px;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translate(0, -50%);
    margin: 0;
    width: auto;
  }
  .widget-input-c input:not([felloTheme] *) {
    border: none;
    padding: 16px 15px;
    color: var(--color-secondary);
    width: 100%;
    font-size: 20px;
    line-height: 26px;
    background: transparent;
  }
}
.widget-input-c input[type=color]:not([felloTheme] *) {
  padding: 0;
  height: 20px;
  cursor: pointer;
  width: 20px;
}
.widget-input-c input[type=color]::-webkit-color-swatch {
  padding: 0;
}
.widget-input-c input[type=color]::-webkit-color-swatch-wrapper {
  border-radius: 4px;
  padding: 0;
}

.border-1 {
  border-radius: var(--border-radius);
  padding: 8px;
  border: 1px solid var(--slate-2);
}

.border-left-1 {
  border-left: 1px solid var(--slate-2);
}

.border-right-1 {
  border-right: 1px solid var(--slate-2) !important;
}

.border-bottom-1 {
  border-bottom: 1px solid var(--slate-2) !important;
}
.border-bottom-1.dashed {
  border-bottom: 1px dashed var(--slate-2) !important;
}

.border-color-primary {
  border-color: var(--color-primary) !important;
}

.rounded {
  --border-radius: 8px;
}

.semi-rounded {
  --border-radius: 4px;
}

.squared {
  --border-radius: 0;
}

.fully-rounded {
  --border-radius: 40px;
}

.textarea-count {
  position: absolute;
  padding: 4px;
  right: 4px;
  bottom: 6px;
  background-color: var(--white);
  text-align: right;
  border-radius: 10px;
  width: fit-content;
  margin: auto;
}

textarea:has(+ .textarea-count) {
  padding-bottom: 25px;
}

.input-prefix-block,
.input-suffix-block {
  display: flex;
  padding: 0 15px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--slate-2);
  background: var(--slate-00);
}

.input-prefix-block {
  border-radius: 8px 0 0 8px;
}
.input-prefix-block + input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.input-prefix-block + input:focus {
  border: 1px solid var(--color-secondary);
}

.input-suffix-block {
  border-radius: 0 8px 8px 0;
}

lib-number-input:has(+ .input-suffix-block) input,
input:has(+ .input-suffix-block) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
lib-number-input:has(+ .input-suffix-block) input:focus,
input:has(+ .input-suffix-block):focus {
  border: 1px solid var(--color-secondary);
}

.input-prefix-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.input-suffix-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

input:has(+ .input-suffix-icon) {
  padding-right: 35px;
}

.input-prefix-icon + input {
  padding-left: 35px;
}

button,
.button,
.md-drppicker.md-drppicker:not(.fello-date-picker) .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  outline: none;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: var(--white);
  background-color: var(--color-primary);
  padding: 16px 25px;
  border-radius: var(--border-radius);
  cursor: pointer;
}
button:disabled:not(.fake-disabled),
.button:disabled:not(.fake-disabled),
.md-drppicker.md-drppicker:not(.fello-date-picker) .btn:disabled:not(.fake-disabled) {
  pointer-events: none;
  filter: opacity(0.5);
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.button.button-mid, .md-drppicker.md-drppicker:not(.fello-date-picker) .button-mid.btn,
button.button-mid {
  font-size: 16px;
  line-height: 20px;
  padding: 10px 12px;
  border-radius: var(--border-radius);
}

.button.button-small, .md-drppicker.md-drppicker:not(.fello-date-picker) .btn,
button.button-small {
  font-size: 14px;
  line-height: 18px;
  padding: 6px 12px;
  border-radius: 4px;
}

.button.button-primary, .md-drppicker.md-drppicker:not(.fello-date-picker) .button-primary.btn, .button.introjs-prevbutton, .md-drppicker.md-drppicker:not(.fello-date-picker) .btn.introjs-prevbutton,
button.button-primary,
button.introjs-prevbutton {
  border: 1px solid var(--color-primary);
  background-color: var(--coral-0);
  color: var(--color-secondary);
}

.button.button-primary-light, .md-drppicker.md-drppicker:not(.fello-date-picker) .button-primary-light.btn,
button.button-primary-light {
  border: 1px solid var(--color-primary);
  background-color: var(--white);
  color: var(--color-primary);
}

.button.button-plain, .md-drppicker.md-drppicker:not(.fello-date-picker) .button-plain.btn,
button.button-plain {
  border: none;
  background-color: transparent;
  color: var(--color-secondary);
}

.button-primary-dark,
.button-primary-dark.button-icon,
.button-primary-dark.introjs-skipbutton,
.button-primary-dark.introjs-skipbutton:hover {
  background: var(--coral-1);
  color: var(--primary);
}

.button.button-secondary, .md-drppicker.md-drppicker:not(.fello-date-picker) .button-secondary.btn,
button.button-secondary {
  border: 1px solid var(--slate-3);
  background-color: var(--body-background-color);
  color: var(--color-secondary);
}

.button.button-tag, .md-drppicker.md-drppicker:not(.fello-date-picker) .button-tag.btn,
button.button-tag {
  padding: 10px 16px;
  border-radius: 100px;
  font-size: 12px;
  white-space: nowrap;
}

.button.button-transparent, .md-drppicker.md-drppicker:not(.fello-date-picker) .button-transparent.btn,
button.button-transparent {
  border: 1px solid var(--color-primary);
  background-color: transparent;
  color: var(--text-color);
}

.button.button-white, .md-drppicker.md-drppicker:not(.fello-date-picker) .button-white.btn,
button.button-white {
  background-color: var(--white);
  color: var(--color-secondary) !important;
  border: 1px solid var(--slate-3);
}

.button.button-red, .md-drppicker.md-drppicker:not(.fello-date-picker) .button-red.btn,
button.button-red {
  background-color: var(--red);
  color: var(--white);
  border: 1px solid var(--slate-3);
}

.button-icon, .introjs-skipbutton.introjs-skipbutton,
.introjs-skipbutton.introjs-skipbutton:hover {
  color: var(--white);
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  height: 32px;
  width: 32px;
  min-width: 32px;
  padding: 0;
  border-radius: 50%;
  transition: all ease 0.3s;
}
.button-icon:not(.fake-disabled):disabled, .introjs-skipbutton:not(.fake-disabled):disabled {
  background-color: var(--slate-00);
  color: var(--plum-2) !important;
  cursor: auto;
  pointer-events: auto;
}

.button-icon.button-small, .button-small.introjs-skipbutton,
.button-small.introjs-skipbutton:hover, .md-drppicker.md-drppicker:not(.fello-date-picker) .button-icon.btn, .md-drppicker.md-drppicker:not(.fello-date-picker) .introjs-skipbutton.introjs-skipbutton.btn {
  height: 24px;
  width: 24px;
  min-width: 24px;
  border-radius: 50%;
  padding: 0;
  font-size: 14px;
}
.button-icon.button-small img, .button-small.introjs-skipbutton img,
.button-small.introjs-skipbutton:hover img, .md-drppicker.md-drppicker:not(.fello-date-picker) .button-icon.btn img, .md-drppicker.md-drppicker:not(.fello-date-picker) .introjs-skipbutton.introjs-skipbutton.btn img {
  width: 15px;
}
.button-icon.button-small.disabled, .button-small.disabled.introjs-skipbutton, .md-drppicker.md-drppicker:not(.fello-date-picker) .button-icon.disabled.btn, .md-drppicker.md-drppicker:not(.fello-date-picker) .disabled.introjs-skipbutton.btn {
  color: var(--slate-00) !important;
  cursor: default !important;
}

.button-icon.button-primary, .button-primary.introjs-skipbutton,
.button-primary.introjs-skipbutton:hover, .button-icon.introjs-prevbutton, .introjs-skipbutton.introjs-skipbutton.introjs-prevbutton {
  background-color: var(--coral-0);
  color: var(--color-secondary);
}

.button-icon.button-secondary, .button-secondary.introjs-skipbutton,
.button-secondary.introjs-skipbutton:hover {
  color: var(--color-primary);
  background-color: var(--background-dark-color);
}

.button-icon.button-slate-1, .button-slate-1.introjs-skipbutton,
.button-slate-1.introjs-skipbutton:hover {
  color: var(--color-secondary);
  background-color: var(--slate-1);
}

.button-icon.button-square, .button-square.introjs-skipbutton,
.button-square.introjs-skipbutton:hover {
  border-radius: 4px;
  aspect-ratio: 1/1;
}
.button-icon.button-square.button-small, .button-square.button-small.introjs-skipbutton, .md-drppicker.md-drppicker:not(.fello-date-picker) .button-icon.button-square.btn, .md-drppicker.md-drppicker:not(.fello-date-picker) .button-square.introjs-skipbutton.btn {
  height: 20px;
  width: 20px;
  min-width: 20px;
}

.dot {
  height: 8px;
  width: 8px;
  min-width: 8px;
  border-radius: 50%;
  background-color: var(--color-primary);
}
.dot.dot--big {
  height: 9px;
  width: 9px;
  min-width: 9px;
}
.dot.dot--xl {
  height: 10px;
  width: 10px;
  min-width: 10px;
}

.button-dashed {
  border: 1px dashed var(--blue);
  background: var(--blue-000);
  color: var(--blue);
}

@keyframes dot-flashing {
  0% {
    opacity: 0.4;
    transform: scale(0.6);
  }
  50% {
    opacity: 0.7;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes slideInBottom {
  0% {
    transform: translateY(100%);
    visibility: visible;
  }
  to {
    transform: translateY(0);
  }
}
.animate__slideInBottom {
  animation-name: slideInBottom;
  animation-duration: 0.2s;
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    visibility: visible;
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    visibility: visible;
  }
  to {
    transform: translateX(0);
  }
}
@keyframes revealContent {
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
.animate__slideInRight {
  animation-name: slideInRight;
  animation-duration: 0.2s;
}

@keyframes bounce {
  0% {
    transform: scale(1.05);
  }
  to {
    transform: scale(1);
  }
}
.animate__bounce {
  animation-name: bounce;
  animation-duration: 0.6s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  transform-origin: center;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.animate__spin {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.chat-answer {
  background-color: var(--color-primary);
  color: var(--white);
  font-size: 20px;
  line-height: 26px;
  border-radius: 35px;
  width: fit-content;
  margin-left: auto;
  padding: 15px 20px;
  position: relative;
  max-width: 90%;
}
.chat-answer::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  bottom: -2px;
  right: 0;
  border-radius: 50%;
  background: inherit;
}

.chat-loader {
  display: flex;
  background-color: var(--color-secondary);
  padding: 20px;
  border-radius: var(--chat-border-radius);
  margin-top: 40px;
  width: fit-content;
  position: relative;
}
.chat-loader::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  bottom: -2px;
  left: 0;
  border-radius: 50%;
  background: inherit;
}
.chat-loader span {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--white);
  animation: dot-flashing 1s infinite linear alternate;
}
.chat-loader span:not(:last-child) {
  margin-right: 8px;
}
.chat-loader span:nth-child(2) {
  animation-delay: 0.5s;
}
.chat-loader span:nth-child(3) {
  animation-delay: 0.8s;
}

.widget-app-container {
  padding-bottom: 10px;
}

@media (min-width: 992px) {
  .chat-bttn-adjustment {
    margin-right: 70px;
  }
}
.radio-option {
  margin-bottom: 16px;
}
.radio-option input:not([felloTheme] *) {
  display: none;
}
.radio-option label {
  display: flex;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--white);
  background-color: var(--plum-light);
  transition: all ease 0.3s;
  align-items: center;
  cursor: pointer;
  padding: 22px 16px;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}
.radio-option label img {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  margin-right: 16px;
}
.radio-option input:checked + label,
.radio-option label:hover {
  background-color: var(--color-primary);
}
.radio-option input:disabled + label {
  opacity: 0.5;
  cursor: none;
  background-color: var(--plum-light);
}

.radio-option-arrow {
  margin-bottom: 16px;
}
.radio-option-arrow input:not([felloTheme] *) {
  display: none;
}
.radio-option-arrow label {
  display: flex;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--text-color);
  background-color: var(--background-light-color);
  transition: all ease 0.3s;
  align-items: center;
  cursor: pointer;
  padding: 16px;
  border-radius: var(--border-radius);
  overflow: hidden;
  border: 1px solid var(--border-color);
}
.radio-option-arrow label p {
  transition: all ease 0.3s;
}
.radio-option-arrow input:checked + label,
.radio-option-arrow label:hover {
  background-color: var(--color-primary);
  color: var(--white);
}
.radio-option-arrow input:checked + label app-icon span,
.radio-option-arrow label:hover app-icon span {
  color: var(--white) !important;
}
.radio-option-arrow input:checked + label p,
.radio-option-arrow input:not(:disabled):hover + label p {
  color: var(--white) !important;
}
.radio-option-arrow input:disabled + label,
.radio-option-arrow input:disabled + label:hover {
  opacity: 0.5;
  cursor: default;
  background-color: var(--background-light-color);
  color: var(--text-color);
}
.radio-option-arrow input:disabled + label app-icon span,
.radio-option-arrow input:disabled + label:hover app-icon span {
  color: var(--color-primary) !important;
}
.radio-option-arrow.condition label {
  padding: 14px 16px;
  font-size: 16px;
}
.radio-option-arrow.condition img {
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 50%;
  object-fit: cover;
}
.radio-option-arrow.condition p {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}

.radio-option-circle {
  margin-bottom: 16px;
}
.radio-option-circle > label {
  background: var(--white);
  border: 1px solid var(--slate-2);
  padding: 12px 16px 12px 44px;
  border-radius: var(--border-radius);
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
}
.radio-option-circle > label:before {
  content: "";
  position: absolute;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: var(--white);
  border: 1px solid var(--slate-2);
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  transition: all ease 0.3s;
}
.radio-option-circle input:not([felloTheme] *) {
  display: none;
}
.radio-option-circle input:not([felloTheme] *):checked + label:before {
  border: 5px solid var(--color-primary);
}
.radio-option-circle input:not([felloTheme] *):not(.fake-disabled):disabled + label:before {
  filter: grayscale(1);
  opacity: 0.8;
}
.radio-option-circle.no-box > label {
  padding: 0 0 0 24px;
  font-size: 14px;
  font-weight: 400;
  border: none;
}
.radio-option-circle.no-box > label:before {
  left: 0;
}
.radio-option-circle.dot-top > label:before {
  top: 2px;
  transform: none;
}

.radio-fill {
  width: 100%;
}
.radio-fill label {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: var(--white);
  border: 1px solid var(--color-primary);
  border-radius: var(--border-radius);
  font-weight: 700;
  font-size: 16px;
  transition: all ease 0.3s;
  cursor: pointer;
  color: var(--color-secondary);
}
.radio-fill input:not([felloTheme] *) {
  display: none;
}
.radio-fill input:not([felloTheme] *):checked + label {
  background: var(--color-secondary);
  border: 1px solid transparent;
  color: var(--white);
}

.radio-box-c {
  display: flex;
  justify-content: center;
}

.radio-box input:not([felloTheme] *) {
  display: none;
}
.radio-box label {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border-color);
  background-color: var(--white);
  padding: 7px 16px;
  transition: all ease 0.3s;
  cursor: pointer;
  margin: 0;
}
.radio-box:first-child label {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.radio-box:last-child label {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}
.radio-box input:checked + label {
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}

.radio-filters {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.radio-filters .filter input {
  display: none;
}
.radio-filters .filter label {
  cursor: pointer;
  font-size: 12px;
  line-height: 15px;
  padding: 8px;
  border-radius: 4px;
  margin: 0;
  font-weight: 500;
  display: flex;
  align-items: center;
  transition: all ease 0.3s;
  border: 1px solid transparent;
}
.radio-filters .filter label a {
  display: none;
}
.radio-filters .filter label:hover a, .radio-filters .filter label.active a {
  display: block;
}
.radio-filters .filter input:checked + label,
.radio-filters .filter label:hover,
.radio-filters .filter label.active {
  background-color: var(--coral-000);
  color: var(--coral-6);
  border: 1px solid var(--color-primary);
}
.radio-filters .filter input:checked + label,
.radio-filters .filter label.active {
  font-weight: 600;
}

.mat-radio-group .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  background-color: var(--slate-0);
}
.mat-radio-group .mat-radio-button.mat-radio-checked .mat-radio-outer-circle {
  background: var(--primary);
  border-color: var(--primary);
}
.mat-radio-group .mat-radio-button.mat-radio-checked .mat-radio-inner-circle {
  background: var(--white);
}
.mat-radio-group .mat-radio-button.mat-radio-checked.mat-radio-disabled .mat-radio-outer-circle {
  background-color: var(--slate-5);
  border-color: var(--slate-5);
}
.mat-radio-group .mat-radio-button .mat-radio-label-content {
  font-weight: 400;
}

.radio-group-panel {
  display: flex;
}
.radio-group-panel .radio-panel {
  font-size: 12px;
  font-weight: 600;
  padding: 5px 12px;
  color: var(--plum-4);
  border: 1px solid var(--slate-2);
  white-space: nowrap;
  margin: 0;
}
.radio-group-panel .radio-panel:first-child {
  border-radius: 4px 0px 0px 4px;
}
.radio-group-panel .radio-panel:last-child {
  border-radius: 0px 4px 4px 0px;
}
.radio-group-panel .radio-panel input {
  display: none;
}
.radio-group-panel .radio-panel:has(input:checked) {
  color: var(--color-primary);
  background-color: var(--coral-000);
  border-color: var(--color-primary);
}
.radio-group-panel.grey .radio-panel {
  color: var(--plum);
  border: 1px solid var(--slate-5);
  background-color: var(--slate-0);
}
.radio-group-panel.grey .radio-panel:has(input:checked) {
  color: var(--plum);
  background-color: var(--slate-1);
  border-color: var(--slate-5);
}

.radio-draggable-filters {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.radio-draggable-filter {
  border-radius: 4px;
  padding: 6px 6px 6px 20px;
  display: flex;
  align-items: center;
  position: relative;
  gap: 8px;
  height: 32px;
}
.radio-draggable-filter input {
  display: none;
}
.radio-draggable-filter label {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
  min-height: 0;
  min-width: 0;
  flex: 1 1 auto;
  color: var(--color-secondary);
  transition: all ease 0.3s;
}
.radio-draggable-filter .drag-icon {
  position: absolute;
  top: 50%;
  left: 7px;
  transform: translateY(-50%);
  cursor: grab;
  opacity: 0;
  transition: all ease 0.3s;
}
.radio-draggable-filter .hover-show {
  opacity: 0;
  transition: all ease 0.3s;
}
.radio-draggable-filter:not(:hover) .hover-display {
  display: none;
}
.radio-draggable-filter:hover {
  background-color: var(--slate-1);
}
.radio-draggable-filter:hover .hover-display-none {
  display: none;
}
.radio-draggable-filter:hover .hover-show,
.radio-draggable-filter:hover .drag-icon {
  opacity: 1;
}
.radio-draggable-filter input:checked + label,
.radio-draggable-filter label.active {
  color: var(--color-primary);
  font-weight: 600;
}
.radio-draggable-filter.cdk-drag-dragging, .radio-draggable-filter.cdk-drag-preview {
  pointer-events: auto !important;
  background-color: var(--slate-00) !important;
}
.radio-draggable-filter.cdk-drag-dragging .drag-icon, .radio-draggable-filter.cdk-drag-preview .drag-icon {
  cursor: grabbing !important;
}

.radio-card-c {
  display: flex;
  gap: 8px;
}
.radio-card-c:has(.radio-card--selected) .radio-card {
  padding: 2px;
  padding-top: 24px;
}

.radio-card {
  position: relative;
  border-radius: 10px;
  flex: 1;
}
.radio-card__content {
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  padding: 12px 16px 16px;
  background-color: var(--white);
  border-radius: 8px;
  height: 100%;
}
.radio-card__selected-text {
  position: absolute;
  top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--white);
}
.radio-card--selected {
  background: var(--plum);
  padding: 2px;
  padding-top: 24px;
}
.radio-card--selected .radio-card__content {
  border-color: transparent;
}

.box-c {
  background-color: var(--background-dark-color);
  border-radius: var(--border-radius);
  padding: 15px;
}
.box-c p {
  font-size: 16px;
  font-weight: 400;
}

.page-container {
  display: block;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 50px 24px;
}

.checkbox-option input:not([felloTheme] *) {
  display: none;
}
.checkbox-option label {
  display: flex;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--text-color);
  background-color: var(--background-light-color);
  transition: all ease 0.3s;
  align-items: center;
  cursor: pointer;
  padding: 18px 16px;
  border-radius: var(--border-radius);
  overflow: hidden;
  position: relative;
  border: 1px solid var(--border-color);
}
.checkbox-option label .tick {
  height: 20px;
  width: 20px;
  border-radius: 5px;
  border: 1px solid var(--dark-grey);
  background-color: var(--white);
  color: var(--white);
  margin-right: 12px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  transition: all ease 0.3s;
}
.checkbox-option input:checked + label .tick {
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
}

.mat-checkbox-inner-container {
  height: 18px !important;
  width: 18px !important;
}

.mat-checkbox-background {
  border-radius: 5px !important;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-background {
  background-color: var(--color-primary);
}

.mat-checkbox-frame {
  border-radius: 5px !important;
  background-color: var(--white) !important;
  border: 1px solid var(--slate-2);
}

.mat-checkbox-checkmark {
  transform: scale(0.8) !important;
}

.checkbox-icon .mat-checkbox-inner-container {
  display: none;
}

.mat-checkbox-layout {
  margin: 0;
}

.checkbox-custom .mat-checkbox-inner-container {
  margin: 0 10px 0 0;
}
.checkbox-custom .mat-checkbox-layout {
  align-items: flex-start;
}

.mat-ripple-element {
  display: none !important;
}

mat-checkbox.flex-box .mat-checkbox-layout {
  display: flex;
  align-items: center;
}
mat-checkbox.flex-box .mat-checkbox-layout .mat-checkbox-label {
  line-height: 18px;
}
mat-checkbox.flex-box.align-start .mat-checkbox-layout {
  align-items: flex-start;
}
mat-checkbox.flex-box.align-start .mat-checkbox-inner-container {
  margin-top: 0;
}

mat-checkbox {
  width: fit-content;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-background {
  background-color: var(--color-primary);
}

.mat-checkbox-disabled {
  filter: grayscale(1);
}

.hamburger-menu {
  background: var(--color-primary);
  border-radius: 10px;
  height: 50px;
  width: 50px;
  padding: 6px 13px;
  z-index: 99;
}
.hamburger-menu .bar1 {
  width: 21px;
  height: 3px;
  background-color: var(--white);
  margin: 7px 0;
  transition: 0.4s;
  border-radius: 5px;
}
.hamburger-menu .bar2 {
  width: 21px;
  height: 3px;
  background-color: var(--white);
  margin: 7px 0;
  transition: 0.4s;
  border-radius: 5px;
  width: 10px;
}
.hamburger-menu .bar3 {
  width: 21px;
  height: 3px;
  background-color: var(--white);
  margin: 7px 0;
  transition: 0.4s;
  border-radius: 5px;
  width: 16px;
}

.hamburger-menu.open {
  background: var(--color-primary);
}
.hamburger-menu.open .bar1 {
  transform: rotate(-45deg) translate(-7px, 8px);
}
.hamburger-menu.open .bar2 {
  opacity: 0;
}
.hamburger-menu.open .bar3 {
  width: 21px;
  transform: rotate(45deg) translate(-6px, -7px);
}

.db-card {
  padding: 24px;
  background: var(--white);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  margin-bottom: 30px;
  border: 1px solid var(--slate-3);
  transition: all ease 0.3s;
}
.db-card .db-card-body {
  margin-top: 24px;
}
.db-card.collapse .db-card-body {
  display: none;
}
.db-card.highlight {
  border: 1px solid var(--color-primary);
  box-shadow: 0px 1.5px 100px rgba(86, 105, 125, 0.15);
}
.db-card .db-card-title {
  color: var(--text-color);
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 4px;
}
@media (max-width: 991px) {
  .db-card .db-card-title {
    font-size: 18px;
    line-height: 22px;
  }
}
.db-card .db-card-desc {
  color: var(--text-color-light-2);
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
.db-card.header-highlight {
  padding: 0;
  overflow: hidden;
}
.db-card.header-highlight .db-card-header {
  padding: 12px;
  background-color: var(--plum);
  color: var(--white);
}
.db-card.header-highlight .db-card-header .db-card-title {
  color: var(--white);
}
.db-card.header-highlight .db-card-body {
  margin: 0;
  padding: 24px;
}
@media (max-width: 767px) {
  .db-card {
    margin-bottom: 24px;
  }
}

.list-item {
  display: flex;
  gap: 20px;
}
.list-item > * {
  width: 100%;
  max-width: 50%;
}

.mw-200 {
  max-width: 200px;
}

.ratings-c {
  display: flex;
}
.ratings-c .rating {
  height: 10px;
  width: 10px;
  min-width: 10px;
  border-radius: 50%;
  margin-right: 4px;
  background-color: var(--color-primary);
  opacity: 0.2;
}
.ratings-c .rating.active {
  opacity: 1;
}

.confidence {
  display: flex;
}
.confidence img {
  height: 30px;
  object-fit: contain;
  object-position: center;
  margin-right: 6px;
}

.link {
  font-weight: 500;
  font-size: 16px;
  color: var(--link-color);
  outline: none;
  background-color: transparent;
  border: none;
  padding: 0;
  line-height: 1;
  width: fit-content;
}
.link:not(.no-hover):hover {
  text-decoration: underline;
}

.link-arrow {
  cursor: pointer;
  background: var(--white);
  border: 1px solid var(--slate-3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 15px 8px 15px 15px;
  border-radius: 10px;
}
.link-arrow app-icon {
  color: var(--color-primary);
  font-size: 28px;
}
.link-arrow.disabled, .link-arrow:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.line-break {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid var(--slate-3);
}
.line-break.dashed {
  border-top: 2px dashed var(--slate-2);
}
.line-break.large {
  margin-top: 32px;
  padding-top: 32px;
}
.line-break.small {
  margin-top: 16px;
  padding-top: 16px;
}
.line-break.x-small {
  margin-top: 8px;
  padding-top: 8px;
}

.vertical-line-break {
  margin: 0 8px;
  height: 100%;
  border-left: 1px solid var(--border-color);
}

.tag {
  padding: 5px 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
}
.tag-primary {
  background-color: var(--primary) !important;
}
.tag-primary-rgb {
  background-color: var(--primary-rgb) !important;
}
.tag-secondary {
  background-color: var(--secondary) !important;
}
.tag-secondary-rgb {
  background-color: var(--secondary-rgb) !important;
}
.tag-salmon {
  background-color: var(--salmon) !important;
}
.tag-salmon-dark {
  background-color: var(--salmon-dark) !important;
}
.tag-salmon-rgb {
  background-color: var(--salmon-rgb) !important;
}
.tag-plum {
  background-color: var(--plum) !important;
}
.tag-plum-000 {
  background-color: var(--plum-000) !important;
}
.tag-plum-00 {
  background-color: var(--plum-00) !important;
}
.tag-plum-1 {
  background-color: var(--plum-1) !important;
}
.tag-plum-2 {
  background-color: var(--plum-2) !important;
}
.tag-plum-3 {
  background-color: var(--plum-3) !important;
}
.tag-plum-4 {
  background-color: var(--plum-4) !important;
}
.tag-plum-5 {
  background-color: var(--plum-5) !important;
}
.tag-plum-7 {
  background-color: var(--plum-7) !important;
}
.tag-plum-dark {
  background-color: var(--plum-dark) !important;
}
.tag-plum-light {
  background-color: var(--plum-light) !important;
}
.tag-plum-dark-rgb {
  background-color: var(--plum-dark-rgb) !important;
}
.tag-white {
  background-color: var(--white) !important;
}
.tag-yellow {
  background-color: var(--yellow) !important;
}
.tag-yellow-1 {
  background-color: var(--yellow-1) !important;
}
.tag-yellow-4 {
  background-color: var(--yellow-4) !important;
}
.tag-yellow-5 {
  background-color: var(--yellow-5) !important;
}
.tag-yellow-6 {
  background-color: var(--yellow-6) !important;
}
.tag-yellow-7 {
  background-color: var(--yellow-7) !important;
}
.tag-yellow-00 {
  background-color: var(--yellow-00) !important;
}
.tag-yellow-3 {
  background-color: var(--yellow-3) !important;
}
.tag-dark-grey {
  background-color: var(--dark-grey) !important;
}
.tag-dark-grey-rgb {
  background-color: var(--dark-grey-rgb) !important;
}
.tag-light-grey {
  background-color: var(--light-grey) !important;
}
.tag-light-grey-rgb {
  background-color: var(--light-grey-rgb) !important;
}
.tag-border-light-grey {
  background-color: var(--border-light-grey) !important;
}
.tag-border-light-grey-rgb {
  background-color: var(--border-light-grey-rgb) !important;
}
.tag-coral-0 {
  background-color: var(--coral-0) !important;
}
.tag-coral-00 {
  background-color: var(--coral-00) !important;
}
.tag-coral-000 {
  background-color: var(--coral-000) !important;
}
.tag-coral-1 {
  background-color: var(--coral-1) !important;
}
.tag-coral-5 {
  background-color: var(--coral-5) !important;
}
.tag-coral-6 {
  background-color: var(--coral-6) !important;
}
.tag-coral-7 {
  background-color: var(--coral-7) !important;
}
.tag-purple {
  background-color: var(--purple) !important;
}
.tag-purple-1 {
  background-color: var(--purple-1) !important;
}
.tag-blue {
  background-color: var(--blue) !important;
}
.tag-blue-00 {
  background-color: var(--blue-00) !important;
}
.tag-blue-000 {
  background-color: var(--blue-000) !important;
}
.tag-blue-1 {
  background-color: var(--blue-1) !important;
}
.tag-blue-2 {
  background-color: var(--blue-2) !important;
}
.tag-blue-4 {
  background-color: var(--blue-4) !important;
}
.tag-blue-6 {
  background-color: var(--blue-6) !important;
}
.tag-blue-7 {
  background-color: var(--blue-7) !important;
}
.tag-black {
  background-color: var(--black) !important;
}
.tag-red {
  background-color: var(--red) !important;
}
.tag-red-00 {
  background-color: var(--red-00) !important;
}
.tag-red-2 {
  background-color: var(--red-2) !important;
}
.tag-red-4 {
  background-color: var(--red-4) !important;
}
.tag-red-6 {
  background-color: var(--red-6) !important;
}
.tag-red-1 {
  background-color: var(--red-1) !important;
}
.tag-red-7 {
  background-color: var(--red-7) !important;
}
.tag-red-light {
  background-color: var(--red-light) !important;
}
.tag-red-light-background {
  background-color: var(--red-light-background) !important;
}
.tag-red-dark {
  background-color: var(--red-dark) !important;
}
.tag-orange {
  background-color: var(--orange) !important;
}
.tag-orange-00 {
  background-color: var(--orange-00) !important;
}
.tag-orange-1 {
  background-color: var(--orange-1) !important;
}
.tag-orange-4 {
  background-color: var(--orange-4) !important;
}
.tag-orange-6 {
  background-color: var(--orange-6) !important;
}
.tag-orange-7 {
  background-color: var(--orange-7) !important;
}
.tag-green-00 {
  background-color: var(--green-00) !important;
}
.tag-green-2 {
  background-color: var(--green-2) !important;
}
.tag-green-4 {
  background-color: var(--green-4) !important;
}
.tag-green-6 {
  background-color: var(--green-6) !important;
}
.tag-green-7 {
  background-color: var(--green-7) !important;
}
.tag-green-000 {
  background-color: var(--green-000) !important;
}
.tag-green-light {
  background-color: var(--green-light) !important;
}
.tag-green {
  background-color: var(--green) !important;
}
.tag-green-dark {
  background-color: var(--green-dark) !important;
}
.tag-slate-0 {
  background-color: var(--slate-0) !important;
}
.tag-slate-00 {
  background-color: var(--slate-00) !important;
}
.tag-slate-000 {
  background-color: var(--slate-000) !important;
}
.tag-slate-1 {
  background-color: var(--slate-1) !important;
}
.tag-slate-2 {
  background-color: var(--slate-2) !important;
}
.tag-slate-3 {
  background-color: var(--slate-3) !important;
}
.tag-slate-4 {
  background-color: var(--slate-4) !important;
}
.tag-slate-5 {
  background-color: var(--slate-5) !important;
}
.tag-slate-8 {
  background-color: var(--slate-8) !important;
}
.tag-slate-7 {
  background-color: var(--slate-7) !important;
}
.tag-slate-6 {
  background-color: var(--slate-6) !important;
}
.tag.square {
  border-radius: 4px;
}
.tag.tag-small {
  font-size: 10px;
  line-height: 12px;
  padding: 3px 10px;
}
.tag.tag-red {
  background-color: var(--red);
  color: var(--white);
}
.tag.tag-red-light {
  background-color: var(--red-1);
  color: var(--red-7);
}
.tag.tag--neutral {
  color: var(--white);
  background: var(--slate-5);
}
.tag.tag--hot {
  color: var(--white);
  background: linear-gradient(98.99deg, #f25917 17.49%, #ffa030 106.77%);
}
.tag.tag--cold {
  color: var(--white);
  background: linear-gradient(98.99deg, #1755f2 17.49%, #30c1ff 106.77%);
}
.tag.tag-orange-1 {
  background-color: var(--orange-1);
  color: var(--orange-7);
}
.tag.tag-plum-1 {
  color: var(--plum);
}
.tag.tag-blue-light {
  background-color: var(--blue-1) !important;
  color: var(--blue-7);
}
.tag.tag-green-light {
  color: var(--green-dark);
}
.tag.tag-slate-1 {
  color: var(--slate-7);
}
.tag.tag-coral-1 {
  color: var(--coral-7);
}
.tag.tag-status {
  background: var(--slate-0);
  border: 1px solid var(--slate-2);
  font-weight: 700;
}
.tag.tag-yellow-1 {
  color: var(--yellow-7);
}
.tag.tag-purple-1 {
  color: var(--purple);
}
.tag.tag-gradient {
  background: linear-gradient(255deg, #f58675 -24.4%, #311e90 134.43%);
}

ngx-skeleton-loader .loader.loader {
  border-radius: var(--border-radius);
}

ngx-skeleton-loader .loader.circle {
  border-radius: 50% !important;
}

.ngx-spinner-overlay > * {
  display: none;
}

.black-loader .loader.progress:before {
  background: rgba(0, 0, 0, 0.6) !important;
}

.step {
  padding-left: 24px;
  position: relative;
}
.step .complete-tick {
  display: none;
}
.step:not(:last-child) {
  margin-bottom: 24px;
}
.step:not(:last-child)::before {
  content: "";
  position: absolute;
  height: calc(100% + 24px);
  width: 2px;
  left: 6px;
  top: 15px;
  background: var(--slate-2);
  opacity: 0.4;
}
.step:not(.complete) .title {
  font-weight: 700;
}
.step::after {
  content: "";
  position: absolute;
  height: 14px;
  width: 14px;
  background: var(--slate-2);
  top: 6px;
  left: 0;
  border-radius: 50%;
}
.step.active::after {
  border: 3px solid var(--color-primary);
  background-color: var(--white);
}
.step.complete .complete-tick {
  display: contents;
}
.step.complete .complete-tick span {
  line-height: 1;
  position: absolute;
  left: -3px;
  top: 4px;
  font-size: 20px;
  color: var(--plum-7) !important;
  z-index: 1;
}
.step.complete .complete-tick span::before {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--white);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.stepper-full-height .mat-horizontal-stepper-wrapper {
  height: 100%;
}
.stepper-full-height .mat-horizontal-content-container {
  flex: 1 1 auto;
}
.stepper-full-height .mat-horizontal-stepper-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.stepper-disable-header-navigation .mat-horizontal-stepper-header {
  pointer-events: none;
  background-color: transparent;
}

.stepper-collapsed-spacing.mat-stepper-horizontal .mat-horizontal-stepper-wrapper {
  align-items: flex-start;
}
.stepper-collapsed-spacing.mat-stepper-horizontal .mat-horizontal-stepper-header {
  height: unset;
  padding: 0;
}
.stepper-collapsed-spacing.mat-stepper-horizontal .mat-horizontal-stepper-header .mat-step-icon {
  color: var(--white);
}
.stepper-collapsed-spacing.mat-stepper-horizontal .mat-horizontal-stepper-header .mat-step-icon-state-number {
  background: var(--plum-2);
}
.stepper-collapsed-spacing.mat-stepper-horizontal .mat-horizontal-stepper-header .mat-step-icon-selected {
  background: var(--color-primary);
}
.stepper-collapsed-spacing.mat-stepper-horizontal .mat-horizontal-stepper-header .mat-step-label {
  font-size: 14px;
  color: var(--plum-5);
  min-width: auto;
}
.stepper-collapsed-spacing.mat-stepper-horizontal .mat-horizontal-stepper-header .mat-step-label-selected,
.stepper-collapsed-spacing.mat-stepper-horizontal .mat-horizontal-stepper-header .mat-step-label-active {
  font-size: 14px;
  color: var(--color-primary);
}
.stepper-collapsed-spacing.mat-stepper-horizontal .mat-horizontal-content-container {
  padding: 0;
  margin-top: 24px;
  width: 100%;
}
.stepper-collapsed-spacing.mat-stepper-horizontal .mat-stepper-horizontal-line {
  margin: 0 10px;
}

.img-h-25 {
  height: 25px;
}
.img-h-35 {
  height: 35px;
}
.img-w-25 {
  width: 25px;
}

.image-group {
  display: flex;
  flex-wrap: wrap;
  margin-top: 35px;
  padding-bottom: 12px;
}
.image-group .uploaded-image-wrap {
  position: relative;
}
.image-group .uploaded-img img {
  width: 70px;
  height: 70px;
  border-radius: 5px;
  margin-bottom: 8px;
  margin-right: 8px;
  object-fit: cover;
}
.image-group .uploaded-img--thumbnail img {
  position: relative;
  opacity: 0.5;
}
.image-group .img-delete-trigger {
  position: absolute;
  top: -4px;
  right: 0;
}

.location-img {
  height: 1.4em;
}

.cdk-drag-preview .uploaded-img img {
  width: 70px;
  height: 70px;
  border-radius: 5px;
}

.cdk-drag-preview button {
  display: none;
}

.mat-tooltip {
  font-size: 12px;
  margin: 8px;
  background-color: var(--slate-8);
  box-shadow: var(--box-shadow);
  padding: 16px !important;
  max-width: 350px !important;
}
.mat-tooltip.mw-200 {
  max-width: 200px !important;
}
.mat-tooltip.padding-small {
  padding: 8px !important;
}

.tooltip-container.full-width {
  max-width: 100% !important;
}
.tooltip-container.spacing-pre {
  white-space: pre;
}

.tooltip-text {
  position: absolute;
  left: 0;
  top: 125%;
  background: var(--slate-8);
  padding: 8px 10px;
  border-radius: 8px;
}

[data-fello-tooltip] {
  position: relative;
}

[data-fello-tooltip]::after {
  visibility: hidden;
  padding: 5px 10px;
  background: var(--black);
  border-radius: 5px;
  font-size: 12px;
  line-height: 19px;
  color: var(--white);
  width: 250px;
  position: absolute;
  z-index: 999;
  left: 50%;
  transform: translate(calc(-50% + var(--tooltip-left)), -100%);
  content: attr(data-fello-tooltip);
  word-break: break-word;
}

[data-fello-tooltip]:hover::after {
  visibility: visible;
}

.introjs-skipbutton.introjs-skipbutton,
.introjs-skipbutton.introjs-skipbutton:hover {
  color: var(--color-primary);
  border: none;
  background: var(--coral-1);
  width: 30px;
  height: 30px;
  min-width: 30px;
  font-size: 20px;
  font-weight: 400;
}

.introjs-tooltipReferenceLayer,
.introjs-tooltipReferenceLayer * {
  font-family: inherit !important;
}
.introjs-tooltipReferenceLayer .introjs-tooltipbuttons {
  padding: 16px;
}
.introjs-tooltipReferenceLayer .introjs-tooltiptext {
  padding: 16px;
}
.introjs-tooltipReferenceLayer .introjs-tooltip-header {
  padding: 16px 16px 0 16px;
}
.introjs-tooltipReferenceLayer .introjs-skipbutton {
  position: static;
}

.md-drppicker.md-drppicker:not(.fello-date-picker) {
  display: flex;
  font-family: var(--font-family);
  color: var(--text-color);
  background-color: var(--white);
  border-radius: var(--border-radius);
  padding: 16px 16px 66px 16px;
}
.md-drppicker.md-drppicker:not(.fello-date-picker):before, .md-drppicker.md-drppicker:not(.fello-date-picker):after {
  border-bottom-color: rgba(0, 0, 0, 0.2);
}
.md-drppicker.md-drppicker:not(.fello-date-picker) .calendar {
  margin: 0;
}
.md-drppicker.md-drppicker:not(.fello-date-picker) .calendar-table {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
}
.md-drppicker.md-drppicker:not(.fello-date-picker) .buttons {
  position: absolute;
  right: 16px;
  bottom: 16px;
}
.md-drppicker.md-drppicker:not(.fello-date-picker) .buttons_input {
  display: flex;
  align-items: center;
  gap: 20px;
}
.md-drppicker.md-drppicker:not(.fello-date-picker) .buttons_input button {
  box-shadow: none;
}
.md-drppicker.md-drppicker:not(.fello-date-picker) .buttons_input svg {
  display: none;
}
.md-drppicker.md-drppicker:not(.fello-date-picker) td.disabled,
.md-drppicker.md-drppicker:not(.fello-date-picker) option.disabled {
  color: var(--slate-00);
}
.md-drppicker.md-drppicker:not(.fello-date-picker) .ranges {
  margin-right: 16px;
}
.md-drppicker.md-drppicker:not(.fello-date-picker) .ranges ul li button {
  font-weight: 600;
  font-size: 12px;
  background: none;
  margin-bottom: 8px;
  padding: 5px 10px;
  color: var(--text-color);
  white-space: nowrap;
  text-align: left;
  display: block;
}
.md-drppicker.md-drppicker:not(.fello-date-picker) .ranges ul li button.active {
  background-color: var(--color-secondary);
  border-radius: 4px;
  color: var(--white);
}
.md-drppicker.md-drppicker:not(.fello-date-picker) .ranges ul li:hover {
  background-color: #eee;
}
.md-drppicker.md-drppicker:not(.fello-date-picker) .btn:hover, .md-drppicker.md-drppicker:not(.fello-date-picker) .btn:focus {
  background-color: var(--color-primary);
}
.md-drppicker.md-drppicker:not(.fello-date-picker) td.active,
.md-drppicker.md-drppicker:not(.fello-date-picker) td.active:hover {
  background-color: var(--color-primary);
}
.md-drppicker.md-drppicker:not(.fello-date-picker) td {
  border-radius: 10px;
  opacity: 1;
}
.md-drppicker.md-drppicker:not(.fello-date-picker) td.off, .md-drppicker.md-drppicker:not(.fello-date-picker) td.off.in-range, .md-drppicker.md-drppicker:not(.fello-date-picker) td.off.start-date, .md-drppicker.md-drppicker:not(.fello-date-picker) td.off.end-date {
  color: var(--plum-4);
}
.md-drppicker.md-drppicker:not(.fello-date-picker) td.in-range {
  background-color: var(--coral-1);
}
.md-drppicker.md-drppicker:not(.fello-date-picker) td.start-date {
  border-radius: 10px 0 0 10px;
}
.md-drppicker.md-drppicker:not(.fello-date-picker) td.end-date {
  border-radius: 0 10px 10px 0;
}
.md-drppicker.md-drppicker:not(.fello-date-picker) td.start-date.end-date {
  border-radius: 10px;
}
.md-drppicker.md-drppicker:not(.fello-date-picker) td.active {
  background: var(--color-primary);
}

.hide-timer + ngx-daterangepicker-material .calendar-time {
  display: none;
}

.mat-calendar-body-cell-content.mat-calendar-body-cell-content {
  border-radius: 10px;
  font-size: 14px;
  width: 75%;
  height: 75%;
}
.mat-calendar-body-cell-content.mat-calendar-body-cell-content.mat-calendar-body-selected {
  background-color: white;
  border: 1px solid var(--color-primary);
}

.steps-progress {
  display: flex;
  gap: 24px;
}
@media (max-width: 991px) {
  .steps-progress {
    flex-wrap: wrap;
  }
}

.steps-count {
  display: flex;
  gap: 16px;
}
.steps-count > div {
  display: flex;
  align-items: center;
  position: relative;
}
.steps-count > div.completed .desc {
  opacity: 0.22;
}
.steps-count > div:not(:last-child) {
  padding-right: 16px;
}
.steps-count > div:not(:last-child):after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid var(--slate-1);
}
.steps-count .number {
  width: 20px;
  min-width: 20px;
  height: 20px;
  background: var(--color-secondary);
  border-radius: 100px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
@media (max-width: 767px) {
  .steps-count {
    gap: 24px;
    flex-direction: column;
  }
  .steps-count > div:not(:last-child) {
    padding-right: 0;
  }
  .steps-count > div:not(:last-child):after {
    display: none;
  }
}

mat-chip.mat-chip {
  border-radius: 4px;
  padding: 3.5px 3.5px 3.5px 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.5px;
  background: var(--plum-1);
}
mat-chip.mat-chip .mat-chip-remove.mat-chip-remove {
  opacity: 1;
}

.tab-pills .mat-tab-header {
  border: none;
  margin-bottom: 24px;
}
.tab-pills .mat-tab-labels {
  background: rgba(0, 0, 0, 0.6);
  padding: 6px;
  width: fit-content;
  gap: 8px;
  border-radius: 50px;
}
.tab-pills .mat-tab-labels .mat-tab-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 12px 26px;
  border-radius: 50px;
  color: var(--white);
  opacity: 1;
  transition: all ease 0.3s;
}
.tab-pills .mat-tab-labels .mat-tab-label.mat-tab-label-active {
  background-color: var(--color-primary);
  color: var(--btn-text-color);
}
.tab-pills .mat-ink-bar,
.tab-pills .mat-tab-header-pagination {
  display: none;
}
.tab-pills.style-white .mat-tab-header {
  margin-bottom: 16px;
}
.tab-pills.style-white .mat-tab-labels {
  background: var(--white);
  padding: 4px;
  width: fit-content;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid var(--slate-2);
  background: var(--white);
  box-shadow: 0 1.5px 3px 0 rgba(86, 105, 125, 0.15);
}
.tab-pills.style-white .mat-tab-labels .mat-tab-label {
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  padding: 6px 10px;
  border-radius: 60px;
  color: var(--color-secondary);
  height: auto;
  min-width: 80px;
}
.tab-pills.style-white .mat-tab-labels .mat-tab-label.mat-tab-label-active {
  background-color: var(--color-secondary);
  color: var(--white);
}
.tab-pills.style-secondary .mat-tab-header {
  margin-bottom: 16px;
}
.tab-pills.style-secondary .mat-tab-labels {
  background: var(--color-secondary);
  padding: 4px;
  width: fit-content;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid var(--slate-2);
  background: var(--color-secondary);
  box-shadow: 0 1.5px 3px 0 rgba(86, 105, 125, 0.15);
}
.tab-pills.style-secondary .mat-tab-labels .mat-tab-label {
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  padding: 6px 10px;
  border-radius: 60px;
  color: var(--white);
  height: auto;
  min-width: 80px;
}
.tab-pills.style-secondary .mat-tab-labels .mat-tab-label.mat-tab-label-active {
  background-color: var(--color-primary);
  color: var(--white);
}
@media (max-width: 767px) {
  .tab-pills .mat-tab-labels {
    width: 100%;
  }
  .tab-pills .mat-tab-labels .mat-tab-label {
    width: 100%;
  }
}

.tabs-style-square .mat-tab-header {
  border: none;
  margin-bottom: 24px;
}
.tabs-style-square .mat-tab-labels {
  width: fit-content;
}
.tabs-style-square .mat-tab-labels .mat-tab-label {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding: 6px 12px;
  color: var(--plum-4);
  border-radius: 0;
  border: 1px solid var(--slate-2);
  opacity: 1;
  transition: all ease 0.3s;
  height: auto;
  min-width: auto;
  background: var(--white);
}
.tabs-style-square .mat-tab-labels .mat-tab-label:first-child {
  border-radius: 4px 0px 0px 4px;
}
.tabs-style-square .mat-tab-labels .mat-tab-label:last-child {
  border-radius: 0px 4px 4px 0px;
}
.tabs-style-square .mat-tab-labels .mat-tab-label.mat-tab-label-active {
  background-color: var(--coral-000);
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
}
.tabs-style-square .mat-ink-bar,
.tabs-style-square .mat-tab-header-pagination {
  display: none;
}
.tabs-style-square.full-width-labels .mat-tab-labels {
  width: 100%;
}
.tabs-style-square.full-width-labels .mat-tab-labels .mat-tab-label {
  width: 100%;
}
.tabs-style-square.h-100 .mat-tab-body-wrapper {
  min-height: 0;
  min-width: 0;
  flex: 1 1 auto;
}
@media (max-width: 767px) {
  .tabs-style-square .mat-tab-labels {
    width: 100%;
  }
  .tabs-style-square .mat-tab-labels .mat-tab-label {
    width: 100%;
  }
}

.mat-tab-group.tab-group-small.stick-header-top .mat-tab-header {
  position: sticky;
  top: 0;
  z-index: 2;
}
.mat-tab-group.tab-group-small .mat-tab-header {
  max-width: 1140px;
  padding: 0 calc((100% - 1140px) / 2);
  box-sizing: content-box;
  background: var(--background-color);
}
.mat-tab-group.tab-group-small .mat-tab-header .mat-tab-labels {
  gap: 16px;
}
.mat-tab-group.tab-group-small .mat-tab-header .mat-tab-label {
  height: 43px;
  padding: 0 12px;
  min-width: unset;
}
.mat-tab-group.tab-group-small .mat-tab-body-wrapper {
  flex: 1 1 auto;
}

.tabs-disabled {
  pointer-events: none;
}

ngu-tile {
  padding: 0 !important;
}
ngu-tile .tile {
  box-shadow: none !important;
}

.carousel-dots {
  display: flex;
  gap: 8px;
  margin: auto;
  justify-content: center;
}
.carousel-dots li {
  cursor: pointer;
  height: 8px;
  width: 8px;
  display: block;
  border-radius: 50%;
  background-color: var(--plum-1);
  transition: all ease 0.3s;
}
.carousel-dots li.dot-dashed {
  height: 6px;
  width: 40px;
  border-radius: 8px;
}
.carousel-dots li.active {
  background-color: var(--color-primary);
}

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-ball-pulse,
.la-ball-pulse > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.la-ball-pulse {
  display: block;
  font-size: 0;
  color: #fff;
}

.la-ball-pulse.la-dark {
  color: #333;
}

.la-ball-pulse > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.la-ball-pulse {
  width: 54px;
  height: 18px;
}

.la-ball-pulse > div:nth-child(1) {
  -webkit-animation-delay: -200ms;
  -moz-animation-delay: -200ms;
  -o-animation-delay: -200ms;
  animation-delay: -200ms;
}

.la-ball-pulse > div:nth-child(2) {
  -webkit-animation-delay: -100ms;
  -moz-animation-delay: -100ms;
  -o-animation-delay: -100ms;
  animation-delay: -100ms;
}

.la-ball-pulse > div:nth-child(3) {
  -webkit-animation-delay: 0ms;
  -moz-animation-delay: 0ms;
  -o-animation-delay: 0ms;
  animation-delay: 0ms;
}

.la-ball-pulse > div {
  width: 10px;
  height: 10px;
  margin: 4px;
  border-radius: 100%;
  -webkit-animation: ball-pulse 1s ease infinite;
  -moz-animation: ball-pulse 1s ease infinite;
  -o-animation: ball-pulse 1s ease infinite;
  animation: ball-pulse 1s ease infinite;
}

.la-ball-pulse.la-sm {
  width: 60px;
  height: 12px;
}

.la-ball-pulse.la-sm > div {
  width: 8px;
  height: 8px;
  margin: 4px;
}

.la-ball-pulse.la-2x {
  width: 108px;
  height: 36px;
}

.la-ball-pulse.la-2x > div {
  width: 20px;
  height: 20px;
  margin: 8px;
}

.la-ball-pulse.la-3x {
  width: 162px;
  height: 54px;
}

.la-ball-pulse.la-3x > div {
  width: 30px;
  height: 30px;
  margin: 12px;
}

/*
 * Animation
 */
@-webkit-keyframes ball-pulse {
  0%, 60%, 100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  30% {
    opacity: 0.1;
    -webkit-transform: scale(0.01);
    transform: scale(0.01);
  }
}
@-moz-keyframes ball-pulse {
  0%, 60%, 100% {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
  30% {
    opacity: 0.1;
    -moz-transform: scale(0.01);
    transform: scale(0.01);
  }
}
@-o-keyframes ball-pulse {
  0%, 60%, 100% {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }
  30% {
    opacity: 0.1;
    -o-transform: scale(0.01);
    transform: scale(0.01);
  }
}
@keyframes ball-pulse {
  0%, 60%, 100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  30% {
    opacity: 0.1;
    -webkit-transform: scale(0.01);
    -moz-transform: scale(0.01);
    -o-transform: scale(0.01);
    transform: scale(0.01);
  }
}
.mat-progress-spinner.spinner-secondary circle, .mat-progress-spinner.spinner-secondary .mat-spinner circle {
  color: var(--blue);
}

.ql-container {
  border: none !important;
}
.ql-container .ql-editor {
  padding: 10px 0px !important;
  word-break: break-word;
  border: none;
}
.ql-container:not(.ql-disabled) .ql-editor {
  padding: 4px 0 !important;
  word-break: break-word;
  min-height: 70px;
}
.ql-container.ql-snow + .ql-container.ql-snow .ql-editor {
  min-height: 60px;
  max-height: max(300px, 40vh);
  overflow-y: auto;
}
.ql-container .mention {
  background-color: var(--blue-1) !important;
  color: var(--blue-7);
  border-radius: 100px;
  padding: 4px 8px;
}
.ql-container .ql-mention-list-item.disabled {
  background-color: var(--slate-0);
  color: var(--plum-3);
}

.ql-editor.ql-blank::before {
  left: auto;
  font-style: normal;
  right: auto;
  font-size: 14px;
  font-weight: 400;
  color: lightgrey;
}

.ql-mention-list-container {
  max-height: 200px;
  overflow-y: auto;
}

.ql-toolbar {
  border-radius: 5px;
  border: none !important;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.15);
}
.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0px;
}

quill-editor.dynamic-tags-input .ql-container {
  border: 1px solid var(--slate-2) !important;
  padding: 7px 12px;
  border-radius: 6px;
  background-color: var(--white);
}
quill-editor.dynamic-tags-input .ql-container:not(.ql-disabled) .ql-editor {
  padding: 0 !important;
  min-height: unset;
}
quill-editor.dynamic-tags-input .ql-container.ql-snow + .ql-container.ql-snow .ql-editor {
  min-height: unset;
}
quill-editor.dynamic-tags-input .ql-editor {
  font-size: 14px;
  line-height: 24px;
  padding: 0 !important;
}
quill-editor.dynamic-tags-input .ql-editor.ql-blank::before, quill-editor.dynamic-tags-input .ql-editor.ql-blank::after {
  color: var(--slate-2);
}
quill-editor.dynamic-tags-input .mention {
  border: 1px solid var(--slate-2) !important;
  background-color: var(--white) !important;
  color: var(--plum-5);
  font-size: 12px;
  line-height: 16px;
  border-radius: 6px;
  padding: 3px 1px;
}
quill-editor.dynamic-tags-input .ql-mention-list-container {
  padding-top: 60px;
  max-height: 360px;
  padding: 2px 0;
  border-radius: 6px;
  border: 1px solid var(--slate-2);
  box-shadow: 0 4px 6px -2px rgba(27, 26, 39, 0.03), 0 12px 16px -4px rgba(27, 26, 39, 0.08);
}
quill-editor.dynamic-tags-input .ql-mention-list-container::before {
  content: "Input Field";
  display: block;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid var(--slate-2);
  margin-bottom: 2px;
}
quill-editor.dynamic-tags-input .ql-mention-list-container::after {
  content: "Dynamic";
  position: absolute;
  top: 12px;
  left: 104px;
  padding: 1px 6px;
  font-size: 12px;
  font-weight: 500;
  background: var(--coral-1);
  color: var(--primary);
  border-radius: 4px;
}
quill-editor.dynamic-tags-input .ql-mention-list-item {
  border-radius: 4px;
  margin: 2px 4px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px !important;
  color: var(--plum-5);
}
quill-editor.dynamic-tags-input .ql-mention-list-item.selected {
  background: var(--slate-0);
}
quill-editor.dynamic-tags-input.single-input .ql-container {
  padding: 7px 30px 7px 12px;
}
quill-editor.dynamic-tags-input.dynamic-suggestions .ql-editor.ql-blank::after {
  content: "Press / to add dynamic values";
  position: absolute;
  top: 12px;
  right: 40px;
  font-size: 12px;
  line-height: 1;
}

@media (max-width: 600px) {
  .new-update-toast.mat-snack-bar-container.mat-snack-bar-container {
    margin-bottom: 110px;
  }
}

.propensity-score {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  border-radius: 100px;
  background: var(--slate-5);
  box-shadow: 0 1.5px 3px 0 rgba(86, 105, 125, 0.15);
  padding: 4px 6px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  color: var(--white) !important;
  min-width: 32px;
}
.propensity-score.ps-very-high {
  background: var(--green-6);
}
.propensity-score.ps-high {
  background: var(--green-4);
}
.propensity-score.ps-moderate {
  background: var(--yellow-6);
}
.propensity-score.ps-low {
  background: var(--orange-6);
}
.propensity-score.ps-very-low {
  background: var(--red-6);
}

.map-buttons-c {
  background-color: var(--white);
  padding: 4px;
  border: 1px solid var(--slate-3);
  border-radius: 4px;
  margin: 0 8px 80px;
}
.map-buttons-c button {
  border: none;
  border-radius: 0;
  padding: 6px 3px;
}
.map-buttons-c button:not(:first-child) {
  border-top: 1px solid var(--slate-3);
}

.map-legends-c {
  border-radius: 5px;
  background: rgba(63, 61, 86, 0.85);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 24px;
  color: var(--white);
}
.map-legends-c .button-icon {
  height: 20px;
  width: 20px;
  min-width: 20px;
}
.map-legends-c .legends {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.map-legends-c .marker {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 2px;
  background-color: var(--slate-5);
}
.map-legends-c .marker.circle {
  border-radius: 50%;
}
.map-legends-c .marker.ps-very-high {
  background-color: var(--green-6);
}
.map-legends-c .marker.ps-high {
  background-color: var(--green-4);
}
.map-legends-c .marker.ps-moderate {
  background-color: var(--yellow-6);
}
.map-legends-c .marker.ps-low {
  background-color: var(--orange-6);
}
.map-legends-c .marker.ps-very-low {
  background-color: var(--red-6);
}
.map-legends-c .marker.yellow-1 {
  background-color: var(--yellow-4);
}
.map-legends-c .marker.blue-light {
  background-color: var(--blue);
}
.map-legends-c .marker.red-light {
  background-color: var(--red);
}
.map-legends-c .marker.red {
  background-color: var(--red);
}
.map-legends-c .marker.slate-1 {
  background-color: var(--slate-3);
}
.map-legends-c .marker.purple-1 {
  background-color: var(--purple);
}

:root {
  --postcard-title-size: 120px;
  --postcard-title-lines: 2;
}

.postcard-c {
  width: 1875px;
  height: 1275px;
  background: var(--white);
  border: 1px solid var(--slate-1);
  color: var(--black);
  position: relative;
  overflow: hidden;
}
.postcard-c .content-editable-field {
  position: relative;
  border: 1px solid transparent;
}
.postcard-c .content-editable-field:not(.disabled) {
  cursor: pointer;
}
.postcard-c .content-editable-field:hover, .postcard-c .content-editable-field.selected {
  border: 1px solid var(--blue-4);
  background: var(--blue-000);
}
.postcard-c .content-editable-field .tag-label {
  font-size: 20px;
  border-radius: 4px 4px 0 0;
  background: var(--blue-4);
  color: var(--white);
  padding: 5px 10px;
  position: absolute;
  bottom: 100%;
  left: -1px;
  display: flex;
  gap: 0 5px;
  align-items: center;
  font-weight: 700;
}
.postcard-c .title {
  color: var(--black);
  font-family: Helvetica, serif;
  font-size: var(--postcard-title-size);
  font-weight: 700;
  line-height: 1;
  height: calc(var(--postcard-title-size) * var(--postcard-title-lines) + var(--postcard-title-size) / 10);
}
.postcard-c.front .title {
  white-space: pre-wrap;
  overflow: hidden;
}
.postcard-c .logo-c {
  height: 85px;
  width: 100%;
  max-width: 600px;
}
.postcard-c .logo-c img {
  height: 100%;
  object-fit: contain;
  object-position: left;
}
.postcard-c .qr-code-c {
  height: 240px;
  width: 240px;
  border: 1px dashed var(--plum);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  gap: 20px;
}
.postcard-c .qr-code-c .qr-tag {
  padding: 6px 20px;
  border-radius: 100px;
  background: var(--blue-1);
  font-size: 22px;
  font-weight: 700;
  color: var(--color-secondary);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}
.postcard-c .arrow {
  height: 75px;
}
.postcard-c .website-url {
  max-width: 600px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 700;
}
.postcard-c .long-website {
  display: none !important;
}
.postcard-c .signature-scaled-up {
  transform: scale(1.7);
  transform-origin: left top;
}
.postcard-c lib-agent-signature .website, .postcard-c lib-agent-signature .email {
  color: black !important;
}
.postcard-c p {
  word-break: break-word;
}

.workflow-step-container .postcard-c {
  border: 5px solid var(--slate-1);
}

.postcard-c .agent-img-c,
.postcard .agent-img-c,
.email .agent-img-c {
  flex-direction: column;
  gap: 10px;
}

.thumbnail-small .postcard-c {
  border: 10px solid var(--slate-2);
  border-radius: 100px;
}

.mat-expansion-panel.mat-expanded {
  background: var(--slate-000);
}

.mat-accordion .mat-expansion-panel-spacing {
  margin: 0;
}

.mat-accordion.plain .mat-expansion-panel-header {
  padding: 0 8px;
}
.mat-accordion.plain .mat-expansion-panel.mat-expanded {
  background: transparent;
}
.mat-accordion.plain .mat-expansion-panel-body {
  padding: 8px;
}

.mat-accordion.category .mat-expansion-panel-header {
  padding: 12px;
  height: auto;
}
.mat-accordion.category .mat-expansion-panel-header:hover {
  background-color: var(--slate-000) !important;
}
.mat-accordion.category .mat-expansion-panel.mat-expanded {
  background: transparent;
}
.mat-accordion.category .mat-expansion-panel-body {
  padding: 12px;
}

.mat-accordion.settings .mat-expansion-panel-header,
.mat-accordion.settings .mat-expansion-panel-header.mat-expanded {
  padding: 0 4px;
  flex-direction: row-reverse;
  gap: 8px;
  height: 48px;
}
.mat-accordion.settings .mat-expansion-panel-header .mat-expansion-indicator::after,
.mat-accordion.settings .mat-expansion-panel-header.mat-expanded .mat-expansion-indicator::after {
  margin-bottom: 6px;
}
.mat-accordion.settings:not(.arrow-plum) .mat-expansion-panel-header .mat-expansion-indicator::after,
.mat-accordion.settings:not(.arrow-plum) .mat-expansion-panel-header.mat-expanded .mat-expansion-indicator::after {
  color: var(--blue);
}
.mat-accordion.settings .mat-expansion-panel-body {
  padding: 0;
}

.panel-collapsible-block .mat-expansion-panel-header,
.panel-collapsible-block .mat-expansion-panel-header.mat-expanded {
  padding: 12px 20px;
  flex-direction: row-reverse;
  gap: 12px;
  height: 55px;
  background: transparent;
}
.panel-collapsible-block .mat-expansion-panel-header .mat-expansion-indicator::after,
.panel-collapsible-block .mat-expansion-panel-header.mat-expanded .mat-expansion-indicator::after {
  margin-bottom: 6px;
}
.panel-collapsible-block:not(.arrow-plum) .mat-expansion-panel-header .mat-expansion-indicator::after,
.panel-collapsible-block:not(.arrow-plum) .mat-expansion-panel-header.mat-expanded .mat-expansion-indicator::after {
  color: var(--blue);
}
.panel-collapsible-block .mat-expansion-panel-header-title {
  margin: 0;
}
.panel-collapsible-block .mat-expansion-indicator {
  transform: rotate(-90deg) !important;
}
.panel-collapsible-block.mat-expanded {
  background: transparent;
}
.panel-collapsible-block.mat-expanded .mat-expansion-indicator {
  transform: rotate(0deg) !important;
}
.panel-collapsible-block .mat-expansion-panel-body {
  padding: 0 20px 20px;
}

mat-expansion-panel.draggable mat-expansion-panel-header {
  position: relative;
  height: 38px;
}
mat-expansion-panel.draggable mat-expansion-panel-header .drag-icon {
  position: absolute;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  cursor: grab;
  width: 25px;
  z-index: 10;
}
mat-expansion-panel.draggable mat-expansion-panel-header:hover {
  background-color: var(--slate-1) !important;
}
mat-expansion-panel.draggable mat-expansion-panel-header:hover .mat-expansion-indicator {
  opacity: 0;
}
mat-expansion-panel.draggable mat-expansion-panel-header:hover .drag-icon {
  opacity: 1;
}
mat-expansion-panel.draggable.cdk-drag-dragging, mat-expansion-panel.draggable.cdk-drag-preview {
  pointer-events: auto !important;
  background-color: var(--slate-00) !important;
}
mat-expansion-panel.draggable.cdk-drag-dragging .drag-icon, mat-expansion-panel.draggable.cdk-drag-preview .drag-icon {
  cursor: grabbing !important;
}
mat-expansion-panel.draggable.cdk-drag-dragging .no-results, mat-expansion-panel.draggable.cdk-drag-preview .no-results {
  display: none;
}

.mat-accordion.arrow-styles .mat-expansion-indicator::after {
  margin-top: -6px;
}

.map-marker {
  --marker-color: var(--slate-4);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-color: var(--marker-color);
  border-style: solid;
  background: var(--white);
  box-shadow: 0 2px 4px 0 rgba(21, 21, 21, 0.1490196078);
  padding: 3px 5px;
  font-size: 12px;
  font-weight: 500;
  color: var(--slate-4) !important;
  min-width: 25px;
  position: relative;
  text-transform: uppercase;
}
.map-marker::before {
  content: "";
  position: absolute;
  display: inline-block;
  top: 100%;
  left: 50%;
  z-index: 0;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--marker-color);
}

.view-propensity .map-marker {
  background-color: var(--marker-color);
  color: var(--white) !important;
  border-radius: 100px;
  padding: 1.5px 7px;
}
.view-propensity .map-marker.ps-very-high {
  --marker-color: var(--green-6);
}
.view-propensity .map-marker.ps-high {
  --marker-color: var(--green-4);
}
.view-propensity .map-marker.ps-moderate {
  --marker-color: var(--yellow-6);
}
.view-propensity .map-marker.ps-low {
  --marker-color: var(--orange-6);
}
.view-propensity .map-marker.ps-very-low {
  --marker-color: var(--red-6);
}

.view-avm .map-marker {
  border-radius: 4px;
  border-color: var(--plum-5);
  background-color: var(--plum-5);
  color: var(--white) !important;
}
.view-avm .map-marker:before {
  border-top-color: var(--plum-5);
}

.view-price .map-marker {
  border-radius: 4px;
  border-color: var(--slate-2);
  background-color: var(--white);
  color: var(--plum-5) !important;
}
.view-price .map-marker.active::before {
  --marker-color: var(--blue);
}
.view-price .map-marker.expired::before {
  --marker-color: var(--red);
}
.view-price .map-marker.deleted::before {
  --marker-color: var(--red);
}
.view-price .map-marker.pending::before {
  --marker-color: var(--purple);
}
.view-price .map-marker.sold::before, .view-price .map-marker.sale::before {
  --marker-color: var(--yellow-4);
}

.map-marker.non-marketing {
  font-size: 0 !important;
  background-image: url("/assets/images/svg/lock.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 8px;
  background-color: var(--white);
  border-color: var(--slate-4);
  padding: 6px 4px;
}
.map-marker.non-marketing:before {
  border-top-color: var(--slate-4);
}

.view-propensity .map-marker.no-propensity-data,
.view-price .map-marker.no-price-data,
.view-avm .map-marker.no-avm-data,
.dot-markers .map-marker {
  height: 0;
  width: 0;
  min-width: 0;
  font-size: 0 !important;
  border-radius: 50%;
  padding: 5px;
  background: var(--slate-4);
  border: 2px solid var(--white);
  box-shadow: 0 1.5px 3px 0 #56697d;
}
.view-propensity .map-marker.no-propensity-data.non-marketing,
.view-price .map-marker.no-price-data.non-marketing,
.view-avm .map-marker.no-avm-data.non-marketing,
.dot-markers .map-marker.non-marketing {
  background: var(--slate-4);
  border: 2px solid var(--white);
}
.view-propensity .map-marker.no-propensity-data:before,
.view-price .map-marker.no-price-data:before,
.view-avm .map-marker.no-avm-data:before,
.dot-markers .map-marker:before {
  display: none;
}

.dot-markers.dot-markers .map-marker {
  background-color: var(--plum-5) !important;
}

.map-marker.highlighted {
  transform: scale(1.4);
  transform-origin: bottom;
}
.map-marker.highlighted:not(.non-marketing) {
  background: var(--color-primary) !important;
  color: var(--white) !important;
  border: none;
}
.map-marker.highlighted:not(.non-marketing)::before {
  border-top-color: var(--color-primary) !important;
}

.map-marker.multiple-units {
  --marker-color: var(--plum-5);
  text-transform: none;
  background: var(--marker-color);
  color: var(--white) !important;
}

.gm-style-iw {
  padding: 0 !important;
  border-radius: 4px !important;
}
.gm-style-iw .gm-style-iw-d {
  overflow: auto !important;
}
.gm-style-iw > button {
  display: none !important;
}

.gm-style .gm-style-iw-tc::after {
  background: var(--slate-8);
}

.la-ball-pulse,
.la-ball-pulse > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.la-ball-pulse {
  display: block;
  font-size: 0;
  color: #fff;
}

.la-ball-pulse > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.la-ball-pulse {
  width: 54px;
  height: 18px;
}

.la-ball-pulse > div:nth-child(1) {
  animation-delay: -200ms;
}

.la-ball-pulse > div:nth-child(2) {
  animation-delay: -100ms;
}

.la-ball-pulse > div:nth-child(3) {
  animation-delay: 0ms;
}

.la-ball-pulse > div {
  width: 10px;
  height: 10px;
  margin: 4px;
  border-radius: 100%;
  animation: ball-pulse 1s ease infinite;
}

.la-ball-pulse.la-sm {
  width: 26px;
  height: 8px;
}

.la-ball-pulse.la-sm > div {
  width: 4px;
  height: 4px;
  margin: 2px;
}

.la-ball-pulse.la-2x {
  width: 108px;
  height: 36px;
}

.la-ball-pulse.la-2x > div {
  width: 20px;
  height: 20px;
  margin: 8px;
}

.la-ball-pulse.la-3x {
  width: 162px;
  height: 54px;
}

.la-ball-pulse.la-3x > div {
  width: 30px;
  height: 30px;
  margin: 12px;
}

/*
 * Animation
 */
@-webkit-keyframes ball-pulse {
  0%, 60%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  30% {
    opacity: 0.1;
    transform: scale(0.01);
  }
}
@-moz-keyframes ball-pulse {
  0%, 60%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  30% {
    opacity: 0.1;
    transform: scale(0.01);
  }
}
@-o-keyframes ball-pulse {
  0%, 60%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  30% {
    opacity: 0.1;
    transform: scale(0.01);
  }
}
@keyframes ball-pulse {
  0%, 60%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  30% {
    opacity: 0.1;
    transform: scale(0.01);
  }
}
.overlay-panel {
  border-radius: 12px;
  background: var(--white);
  box-shadow: 0px 1.5px 7px rgba(86, 105, 125, 0.35);
  flex-direction: column;
}
.overlay-panel .panel-body {
  padding: 12px 16px;
}
.overlay-panel .panel-footer {
  padding: 12px 16px;
  border-top: 1px solid var(--slate-1);
}
.overlay-panel.w-500 {
  width: 500px;
}
.overlay-panel.w-350 {
  width: 350px;
}
.overlay-panel.w-300 {
  width: 300px;
}
.overlay-panel.caret-top {
  position: relative;
}
.overlay-panel.caret-top:after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--white);
}

.mat-expansion-panel.expansion-panel-transparent {
  background: transparent;
  box-shadow: none;
}
.mat-expansion-panel.expansion-panel-transparent .mat-expansion-panel-header {
  height: auto;
  line-height: 1;
  flex-direction: row-reverse;
  justify-content: start;
  padding: 0;
}
.mat-expansion-panel.expansion-panel-transparent .mat-expansion-panel-header-title {
  margin: 0 0 0 16px;
}
.mat-expansion-panel.expansion-panel-transparent .mat-expansion-panel-header .mat-expansion-indicator {
  line-height: 0;
  margin-left: 2px;
}
.mat-expansion-panel.expansion-panel-transparent .mat-expansion-panel-header:hover {
  background: transparent;
}
.mat-expansion-panel.expansion-panel-transparent .mat-expansion-panel-body {
  font-size: 14px;
  padding: 0;
  margin-top: 8px;
}

.line-chart-defaults .ngx-charts text {
  fill: var(--text-color) !important;
}
.line-chart-defaults .ngx-charts .gridline-path {
  stroke: var(--slate-0);
}
.line-chart-defaults .ngx-charts {
  position: relative;
  margin-left: -2%;
  border: transparent;
}
.line-chart-defaults .ngx-charts .tooltip-anchor {
  fill: var(--text-color) !important;
}
.line-chart-defaults [ngx-charts-line] .line {
  stroke-width: 2px;
}
.line-chart-defaults [ngx-charts-x-axis-ticks] > g ~ g {
  display: none;
}

.content-performance-chart:not(lib-fello-combo-chart) .ngx-charts {
  margin-left: -5px;
}
.content-performance-chart .ngx-charts {
  position: relative;
  border: transparent;
  margin-left: -10px;
}
.content-performance-chart .ngx-charts text {
  fill: var(--text-color) !important;
}
.content-performance-chart .ngx-charts [ngx-charts-y-axis-ticks] g:nth-child(2) .gridline-path {
  stroke: var(--slate-3);
}
.content-performance-chart .ngx-charts .gridline-path {
  stroke: var(--slate-0);
}
.content-performance-chart .ngx-charts .tooltip-anchor {
  fill: var(--text-color) !important;
}
.content-performance-chart [ngx-charts-line] .line {
  stroke-width: 2px;
}
.content-performance-chart:not(.bar-stacked-horizontal) [ngx-charts-x-axis-ticks] > g ~ g {
  display: none;
}
.content-performance-chart .axis.x text {
  font-size: 8px;
}

.bill-c {
  background: #ffffff;
  border: 1px solid var(--slate-2);
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
}

.bill-header {
  padding: 15px 24px;
  background-color: var(--color-secondary);
}

.bill-block {
  padding: 16px 24px;
  border-bottom: 1px solid var(--slate-2);
}
.bill-block.dashed {
  border-bottom: 1px dashed var(--slate-2);
}
.bill-block .line-break.dashed {
  border-top: 1px dashed var(--slate-2);
}

*,
::after,
::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: var(--font-family);
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: var(--font-weight);
  color: var(--text-color);
  background-color: var(--background-light-color);
}

ul,
ol,
li {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.h-5 {
  height: 5% !important;
}

.h-10 {
  height: 10% !important;
}

.h-15 {
  height: 15% !important;
}

.h-20 {
  height: 20% !important;
}

.h-25 {
  height: 25% !important;
}

.h-30 {
  height: 30% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

.h-45 {
  height: 45% !important;
}

.h-50 {
  height: 50% !important;
}

.h-55 {
  height: 55% !important;
}

.h-60 {
  height: 60% !important;
}

.h-65 {
  height: 65% !important;
}

.h-70 {
  height: 70% !important;
}

.h-75 {
  height: 75% !important;
}

.h-80 {
  height: 80% !important;
}

.h-85 {
  height: 85% !important;
}

.h-90 {
  height: 90% !important;
}

.h-95 {
  height: 95% !important;
}

.h-100 {
  height: 100% !important;
}

@media (min-width: 992px) {
  .h-lg-5 {
    height: 5% !important;
  }
  .h-lg-10 {
    height: 10% !important;
  }
  .h-lg-15 {
    height: 15% !important;
  }
  .h-lg-20 {
    height: 20% !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
  .h-lg-30 {
    height: 30% !important;
  }
  .h-lg-35 {
    height: 35% !important;
  }
  .h-lg-40 {
    height: 40% !important;
  }
  .h-lg-45 {
    height: 45% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-55 {
    height: 55% !important;
  }
  .h-lg-60 {
    height: 60% !important;
  }
  .h-lg-65 {
    height: 65% !important;
  }
  .h-lg-70 {
    height: 70% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-80 {
    height: 80% !important;
  }
  .h-lg-85 {
    height: 85% !important;
  }
  .h-lg-90 {
    height: 90% !important;
  }
  .h-lg-95 {
    height: 95% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
}
.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.p-0 {
  padding: 0px !important;
}

.mt-1 {
  margin-top: 4px !important;
}

.pt-1 {
  padding-top: 4px !important;
}

.mb-1 {
  margin-bottom: 4px !important;
}

.pb-1 {
  padding-bottom: 4px !important;
}

.ml-1 {
  margin-left: 4px !important;
}

.pl-1 {
  padding-left: 4px !important;
}

.mr-1 {
  margin-right: 4px !important;
}

.pr-1 {
  padding-right: 4px !important;
}

.p-1 {
  padding: 4px !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.pt-2 {
  padding-top: 8px !important;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.pb-2 {
  padding-bottom: 8px !important;
}

.ml-2 {
  margin-left: 8px !important;
}

.pl-2 {
  padding-left: 8px !important;
}

.mr-2 {
  margin-right: 8px !important;
}

.pr-2 {
  padding-right: 8px !important;
}

.p-2 {
  padding: 8px !important;
}

.mt-3 {
  margin-top: 16px !important;
}

.pt-3 {
  padding-top: 16px !important;
}

.mb-3 {
  margin-bottom: 16px !important;
}

.pb-3 {
  padding-bottom: 16px !important;
}

.ml-3 {
  margin-left: 16px !important;
}

.pl-3 {
  padding-left: 16px !important;
}

.mr-3 {
  margin-right: 16px !important;
}

.pr-3 {
  padding-right: 16px !important;
}

.p-3 {
  padding: 16px !important;
}

.mt-4 {
  margin-top: 28px !important;
}

.pt-4 {
  padding-top: 28px !important;
}

.mb-4 {
  margin-bottom: 28px !important;
}

.pb-4 {
  padding-bottom: 28px !important;
}

.ml-4 {
  margin-left: 28px !important;
}

.pl-4 {
  padding-left: 28px !important;
}

.mr-4 {
  margin-right: 28px !important;
}

.pr-4 {
  padding-right: 28px !important;
}

.p-4 {
  padding: 28px !important;
}

.mt-5 {
  margin-top: 48px !important;
}

.pt-5 {
  padding-top: 48px !important;
}

.mb-5 {
  margin-bottom: 48px !important;
}

.pb-5 {
  padding-bottom: 48px !important;
}

.ml-5 {
  margin-left: 48px !important;
}

.pl-5 {
  padding-left: 48px !important;
}

.mr-5 {
  margin-right: 48px !important;
}

.pr-5 {
  padding-right: 48px !important;
}

.p-5 {
  padding: 48px !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.m-auto {
  margin: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-3 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-4 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.py-5 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

[hidden] {
  visibility: hidden !important;
}

.flex-box {
  display: flex !important;
}
.flex-box-center {
  display: flex !important;
  align-items: center !important;
}
.flex-box-all-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.flex-box-justify-center {
  display: flex !important;
  justify-content: center !important;
}
.flex-box-between {
  display: flex !important;
  justify-content: space-between !important;
}
.flex-box-end {
  display: flex !important;
  justify-content: flex-end !important;
}
.flex-box-center-end {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}
.flex-box-align-start {
  display: flex !important;
  align-items: flex-start !important;
}
.flex-box-center-between {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.flex-box-center-around {
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
}
.flex-box-top-between {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.flex-box-end {
  display: flex !important;
  justify-content: flex-end !important;
}
.flex-box-direction-column {
  display: flex !important;
  flex-direction: column !important;
}
.flex-box-direction-row-reverse {
  display: flex !important;
  flex-direction: row-reverse !important;
}
.flex-box-start-between {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.flex-box-gap-1 {
  gap: 0.5em;
}
.flex-box-gap-2 {
  gap: 1em;
}
.flex-box-gap-3 {
  gap: 1.5em;
}
.flex-box-gap-4 {
  gap: 2em;
}
.flex-box-gap-5 {
  gap: 2.5em;
}

@media (min-width: 992px) {
  .flex-box-lg {
    display: flex !important;
  }
  .flex-box-lg-center {
    display: flex !important;
    align-items: center !important;
  }
  .flex-box-lg-all-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .flex-box-lg-justify-center {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-box-lg-between {
    display: flex !important;
    justify-content: space-between !important;
  }
  .flex-box-lg-end {
    display: flex !important;
    justify-content: flex-end !important;
  }
  .flex-box-lg-center-end {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
  }
  .flex-box-lg-align-start {
    display: flex !important;
    align-items: flex-start !important;
  }
  .flex-box-lg-center-between {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
  .flex-box-lg-center-around {
    display: flex !important;
    align-items: center !important;
    justify-content: space-around !important;
  }
  .flex-box-lg-top-between {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
  .flex-box-lg-end {
    display: flex !important;
    justify-content: flex-end !important;
  }
  .flex-box-lg-direction-column {
    display: flex !important;
    flex-direction: column !important;
  }
  .flex-box-lg-direction-row {
    display: flex !important;
    flex-direction: row !important;
  }
  .flex-box-lg-direction-row-reverse {
    display: flex !important;
    flex-direction: row-reverse !important;
  }
  .flex-box-lg-start-between {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .flex-box-lg-gap-1 {
    gap: 0.5em;
  }
  .flex-box-lg-gap-2 {
    gap: 1em;
  }
  .flex-box-lg-gap-3 {
    gap: 1.5em;
  }
  .flex-box-lg-gap-4 {
    gap: 2em;
  }
  .flex-box-lg-gap-5 {
    gap: 2.5em;
  }
}
.flex-grow {
  flex-grow: 1;
}

.flex-item-fixed-sizing {
  min-height: 0;
  min-width: 0;
  flex: 0 0 auto;
}

.flex-item-auto-sizing {
  min-height: 0;
  min-width: 0;
  flex: 1 1 auto;
}

.flex-item-one-half-sizing {
  min-height: 0;
  min-width: 0;
  flex: 0.5 1 auto;
}

.flex-direction-row {
  flex-direction: row !important;
}
.flex-direction-column {
  flex-direction: column !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

@media (min-width: 767px) {
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
}
@media (max-width: 767px) {
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
}
.flex-1 {
  flex: 1 !important;
}

@media (min-width: 767px) {
  .flex-lg-box-center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 767px) {
  .flex-lg-box-top-between {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}
.object-fit-contain {
  object-fit: contain !important;
}
.object-fit-cover {
  object-fit: cover !important;
}

.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}

.abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-y-auto {
  overflow-y: auto !important;
}
.overflow-x-auto {
  overflow-x: auto !important;
}

.mandatory-x-scroll-snapping {
  scroll-snap-type: x mandatory;
}

.scroll-snap-align-start {
  scroll-snap-align: start;
}

.c-pointer {
  cursor: pointer;
}

.c-default {
  cursor: default;
}

.border-top-1 {
  border-top: 1px solid var(--border-color) !important;
}

.border-bottom-1 {
  border-bottom: 1px solid var(--border-color) !important;
}

.border-left-1 {
  border-left: 1px solid var(--border-color) !important;
}

.border-right-1 {
  border-right: 1px solid var(--border-color) !important;
}

.border-top-2 {
  border-top: 2px solid var(--border-color) !important;
}

.border-bottom-2 {
  border-bottom: 2px solid var(--border-color) !important;
}

.border-left-2 {
  border-left: 2px solid var(--border-color) !important;
}

.border-right-2 {
  border-right: 2px solid var(--border-color) !important;
}

.buttons-c button:not(:last-of-type) {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .buttons-c {
    display: flex;
    gap: 16px;
    flex-direction: row-reverse;
  }
  .buttons-c button:not(:last-of-type) {
    margin: 0;
  }
}

.container {
  max-width: 1380px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .container {
    max-width: 100%;
    display: block;
  }
  .container .cards-wrapper {
    max-width: 100%;
  }
}

body {
  --background-color: var(--slate-0);
  --background-light-color: var(--white);
  --background-dark-color: var(--slate-1);
  --border-color: var(--slate-2);
  --input-background-color: var(--white);
}

button:disabled,
.button:disabled {
  display: initial;
}

.card-collapse-button {
  border-radius: var(--border-radius);
}

input:not([felloTheme] *),
textarea:not([felloTheme] *) {
  border: 1px solid var(--border-color);
}

label {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: inline-block;
  margin-bottom: 10px;
}

input:not([felloTheme] *) {
  background: var(--white);
  border: 1px solid var(--slate-2);
  border-radius: var(--border-radius);
  padding: 8px 12px;
  font-size: 16px;
  line-height: 24px;
}
@media (max-width: 991px) {
  input:not([felloTheme] *):disabled:not(.fake-disabled) {
    opacity: 1;
    background-color: var(--slate-2);
    pointer-events: none;
  }
}

.form-field.prefix input[type=text],
.form-field.prefix input[type=number] {
  padding-left: 25px;
}
.form-field.prefix.small input[type=text],
.form-field.prefix.small input[type=number] {
  padding-left: 18px;
}
.form-field.prefix.small:after {
  font-size: 12px;
  line-height: 17px;
  top: 6px;
  left: 7px;
}
.form-field.suffix input[type=text],
.form-field.suffix input[type=number] {
  padding-right: 25px;
}
.form-field.suffix.small input[type=text],
.form-field.suffix.small input[type=number] {
  padding-right: 18px;
}
.form-field.suffix.small:before {
  font-size: 12px;
  line-height: 17px;
  top: 6px;
  right: 7px;
}
.form-field.prefix-icon input[type=text],
.form-field.prefix-icon input[type=number] {
  padding-left: 28px;
}
.form-field.prefix-icon lib-icon {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}
.form-field:after {
  top: 9px;
  left: 12px;
  font-size: 16px;
  line-height: 24px;
  color: var(--slate-5);
}
.form-field:before {
  top: 9px;
  right: 12px;
  font-size: 16px;
  line-height: 24px;
  color: var(--slate-5);
}
.form-field mat-datepicker-toggle {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-primary);
}
.form-field.small mat-datepicker-toggle {
  transform: translateY(-50%) scale(0.8);
}
.form-field .arrow {
  border: solid var(--color-primary);
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 3px;
  transform: translateY(calc(-50% - 1.5px)) rotate(45deg);
  position: absolute;
  top: 50%;
  right: 8px;
  pointer-events: none;
}

@media (min-width: 768px) {
  input[type=text]:not([felloTheme] *),
input[type=password]:not([felloTheme] *),
textarea {
    font-size: 16px;
  }
}
.consent-checkbox {
  transform: translateY(2px);
  opacity: 1 !important;
}

.step {
  padding-left: 24px;
  position: relative;
}
.step .complete-tick {
  display: none;
}
.step:not(:last-child) {
  margin-bottom: 24px;
}
.step:not(:last-child)::before {
  content: "";
  position: absolute;
  height: calc(100% + 24px);
  width: 2px;
  left: 6px;
  top: 15px;
  background: var(--slate-2);
  opacity: 0.4;
}
.step:not(.complete) .title {
  font-weight: 700;
}
.step::after {
  content: "";
  position: absolute;
  height: 14px;
  width: 14px;
  background: var(--slate-2);
  top: 6px;
  left: 0;
  border-radius: 50%;
}
.step.active::after {
  border: 3px solid var(--color-primary);
  background-color: var(--white);
}
.step.complete .complete-tick {
  display: contents;
}
.step.complete .complete-tick span {
  line-height: 1;
  position: absolute;
  left: -3px;
  top: 4px;
  font-size: 20px;
  color: var(--plum-7) !important;
  z-index: 1;
}
.step.complete .complete-tick span::before {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--white);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.map-marker-label {
  background: var(--white);
  border-radius: 5px;
  padding: 6px 10px;
}

.mat-menu-panel button.mat-menu-item {
  color: var(--text-color);
}

.calendly-overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
}